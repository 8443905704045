import { MaterialIcons } from '@expo/vector-icons';
import {
  Modal,
  Button,
  Icon,
  Heading,
  VStack,
  FormControl,
  Input,
  Pressable,
  WarningOutlineIcon,
} from 'native-base';
import { FC, useEffect, useState } from 'react';
import { RootState } from '../../state/store';
import { useDispatch, useSelector } from 'react-redux';
import { setResetPasswordModalVisibility } from '../../state/app/app-configuration-slice';
import { presentToast, resetPassword } from '../../firebase-utilities';

const ResetPasswordModal: FC = () => {
  const dispatch = useDispatch();
  const debugMode = useSelector(
    (state: RootState) => state.appConfiguration?.debugMode ?? false
  );
  const modalVisible = useSelector(
    (state: RootState) =>
      state.appConfiguration?.resetPasswordModalVisible ?? false
  );
  const [email, setEmail] = useState('');
  const [emailIsDirty, setEmailIsDirty] = useState(false);
  const [emailIsInvalid, setEmailIsInvalid] = useState(true);
  const [emailResetSent, setEmailResetSent] = useState(false);
  const emailIsValid = (email: string) => {
    const textIsEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(email);
    const emailIsValid = textIsEmail;
    return emailIsValid;
  };
  const handleEmailChange = (text: string) => {
    setEmail(text);
    if (!emailIsDirty) {
      setEmailIsDirty(true);
    }
    setEmailIsDirty(true);
    setEmailIsInvalid(!emailIsValid(text));
  };

  const resetPasswordForEmail = async () => {
    if (emailIsInvalid) {
      return;
    }
    try {
      await resetPassword(email);
      setEmailResetSent(true);
    } catch (e) {
      console.error(e);
      presentToast(
        'Something went wrong resetting password, please try again later',
        debugMode,
        '#be123c',
        5000
      );
    }
  };

  return (
    <Modal
      isOpen={modalVisible}
      onClose={() => {
        setEmail('');
        setEmailIsDirty(false);
        setEmailIsInvalid(true);
        setEmailResetSent(false);
        dispatch(setResetPasswordModalVisibility({ visible: false }));
      }}
      size="full"
      maxWidth={380}
      marginLeft="auto"
      marginRight="auto"
      alignContent="center"
    >
      <Modal.Content
        height={
          (!emailResetSent && emailIsDirty && emailIsInvalid ? 25 : 0) +
          (emailResetSent ? 175 : 255)
        }
      >
        <Modal.Body>
          {!emailResetSent ? (
            <VStack space={1} alignItems="center" height="100%">
              <Heading
                size="md"
                textAlign="center"
                _dark={{ color: 'primary.900' }}
                _light={{ color: 'secondary.500' }}
                marginBottom={5}
              >
                Forgot your password? Please enter an email to reset the
                password
              </Heading>
              <FormControl
                isInvalid={emailIsDirty && emailIsInvalid}
                w="100%"
                marginBottom={5}
                isRequired={emailIsDirty && true}
              >
                <Input
                  _dark={{ focusOutlineColor: 'secondary.500' }}
                  _light={{ focusOutlineColor: 'primary.900' }}
                  variant="outline"
                  placeholder="Email..."
                  isInvalid={emailIsDirty && emailIsInvalid}
                  type="text"
                  onChangeText={handleEmailChange}
                  value={email}
                  InputRightElement={
                    <Pressable
                      onPress={() => {
                        setEmailIsInvalid(true);
                        setEmail('');
                      }}
                    >
                      <Icon
                        as={<MaterialIcons name="cancel" />}
                        size={5}
                        mr="2"
                        color="muted.400"
                      />
                    </Pressable>
                  }
                />
                <FormControl.ErrorMessage
                  leftIcon={<WarningOutlineIcon size="xs" />}
                >
                  You must provide a valid email...
                </FormControl.ErrorMessage>
              </FormControl>
              <Button.Group
                direction="column"
                width="full"
                size="full"
                maxWidth={500}
                _text={{ fontSize: 15 }}
                alignSelf={'flex-end'}
              >
                <Button
                  bg="gray.700"
                  onPress={() => resetPasswordForEmail()}
                  height={41}
                  isDisabled={emailIsInvalid}
                >
                  Reset Password
                </Button>
                <Button
                  bg="gray.700"
                  onPress={() => {
                    dispatch(
                      setResetPasswordModalVisibility({ visible: false })
                    );
                    setEmail('');
                    setEmailIsDirty(false);
                    setEmailIsInvalid(true);
                    setEmailResetSent(false);
                  }}
                  height={41}
                >
                  Cancel
                </Button>
              </Button.Group>
            </VStack>
          ) : (
            <VStack space={1} alignItems="center" height="100%">
              <Heading
                size="md"
                textAlign="center"
                _dark={{ color: 'primary.900' }}
                _light={{ color: 'secondary.500' }}
                marginBottom={5}
              >
                Password reset has been sent, please check your email and you
                spam folders for a link to reset your email
              </Heading>
              <Button.Group
                direction="column"
                width="full"
                size="full"
                maxWidth={500}
                _text={{ fontSize: 15 }}
                alignSelf={'flex-end'}
              >
                <Button
                  bg="gray.700"
                  onPress={() => {
                    dispatch(
                      setResetPasswordModalVisibility({ visible: false })
                    );
                    setEmail('');
                    setEmailIsDirty(false);
                    setEmailIsInvalid(true);
                  }}
                  height={41}
                >
                  Close Modal
                </Button>
              </Button.Group>
            </VStack>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
export default ResetPasswordModal;
