import { Timestamp } from 'firebase/firestore';

export interface IGame {
  players: Player[];
  playerIds: string[];
  gameArchivedDate?: Timestamp;
  createdDate: Timestamp;
  createdBy: string;
}

export interface FirebaseGame extends IGame {
  gameId: string;
}

export interface Player {
  userId: string;
  secondsPlayed: number;
}

export interface FindItUser {
  currentGameId?: string;
  displayName?: string;
  userId: string;
  stats?: UserStats;
}

export interface UserStats {
  data: UserStatsData;
  expirationDate: Timestamp;
}

export interface UserStatsData {
  averagePercentFound: number;
  fastestFinish: number;
  gamesPlayed: number;
  mostPercentFound: number;
  totalTimePlayed: number;
}

export interface GameBox {
  displayName: string;
  id: string;
  category: GameBoxCategory;
  difficulty: GameDifficulty;
  checked: boolean;
  imageUrl?: string;
  composedOf?: ComposedOf;
}

export interface FirebaseGameBoxDocument extends GameBox {
  gameId: string;
}

export interface ComposedOf {
  idsOrCategory: string[] | GameBoxCategory;
  count: number;
}

export enum ComposedOfDiscriminatorEnum {
  categoryCount = 'categoryCount',
  ids = 'ids',
}

export enum GameDifficulty {
  Easy = 0,
  Medium = 1,
  Hard = 2,
}

export const AllGameBoxCategories = [
  'ANIMALS',
  'STATE LICENSE PLATES',
  'NATURE',
  'NEAR THE HOUSE',
  'ROAD SIGNS',
  'ROADSIDE SIGHTINGS',
  'SPORTS',
  'STRUCTURES & PLACES',
  'VEHICLES',
] as const;

export type GameBoxCategory = (typeof AllGameBoxCategories)[number];
