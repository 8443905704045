import { MaterialIcons } from '@expo/vector-icons';
import { Modal, Button, Icon, Heading, VStack, Text } from 'native-base';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';

export interface StartNewGameWarningModalProps {
  modalVisible: boolean;
  getResponse: (response: boolean) => void;
}

export const StartNewGameWarningModal: FC<StartNewGameWarningModalProps> = ({
  modalVisible,
  getResponse,
}) => {
  const game = useSelector((state: RootState) => state?.game?.game);
  const currentUserId = useSelector(
    (state: RootState) => state?.appConfiguration?.currentUser?.uid
  );
  const [displayMessage, setDisplayMessage] = useState(
    'Starting a new game will remove you from your current game. Do you wish to continue?'
  );
  useEffect(() => {
    if (
      (game?.playerIds?.length ?? 0) > 1 &&
      (game?.createdBy === currentUserId ||
        game?.playerIds?.at(0) === currentUserId)
    ) {
      setDisplayMessage(
        'Starting a new game will end your current game for all users. Do you wish to continue?'
      );
    }
    if ((game?.playerIds?.length ?? 0) > 1) {
      setDisplayMessage(
        'Starting a new game will remove you from your current game (it will continue for others). Do you wish to continue?'
      );
    }
    setDisplayMessage(
      'Starting a new game will remove you from your current game. Do you wish to continue?'
    );
  }, [game, currentUserId]);
  return (
    <Modal
      isOpen={modalVisible}
      onClose={getResponse}
      size="full"
      maxWidth={380}
      marginLeft="auto"
      marginRight="auto"
      alignContent="center"
    >
      <Modal.Content height="420">
        <Modal.Body>
          <VStack space={1} alignItems="center">
            <Heading
              size="lg"
              textAlign="center"
              _dark={{ color: 'primary.900' }}
              _light={{ color: 'secondary.500' }}
            >
              Current game in progress
            </Heading>
            <Icon
              size="200"
              as={MaterialIcons}
              name="pan-tool"
              _dark={{ color: 'primary.900' }}
              _light={{ color: 'secondary.500' }}
            />
            <Text textAlign="center">{displayMessage}</Text>
            <Button.Group
              direction="column"
              width="full"
              size="full"
              maxWidth={500}
              _text={{ fontSize: 15 }}
            >
              <Button
                onPress={() => getResponse(true)}
                _dark={{ bg: 'primary.900' }}
                _light={{ bg: 'secondary.500' }}
                height={41}
              >
                Yes
              </Button>
              <Button
                bg="gray.700"
                onPress={() => getResponse(false)}
                height={41}
              >
                No
              </Button>
            </Button.Group>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
