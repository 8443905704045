import {
  Action,
  AnyAction,
  PayloadAction,
  configureStore,
} from '@reduxjs/toolkit';
import {
  combineEpics,
  createEpicMiddleware,
  StateObservable,
} from 'redux-observable';
import { catchError, filter, map, Observable, tap } from 'rxjs';
import { presentToast } from '../firebase-utilities';
import {
  appConfigurationEpics,
  appConfigurationReducer,
  loadDebugModeFailure,
  noOp,
} from './app/app-configuration-slice';
import { AppConfigurationState } from './app/app-configuration-types';
import {
  gameReducer,
  gameEpics,
  startNewGameFailure,
  joinGameFailure,
  continueGameFailure,
} from './game/game-slice';
import { GameState } from './game/game-types';

export type AppState = {
  game: GameState;
  appConfiguration: AppConfigurationState;
};

const failureEpic$ = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter((action) =>
      [
        startNewGameFailure.type,
        joinGameFailure.type,
        continueGameFailure.type,
        loadDebugModeFailure.type,
      ].includes(action.type)
    ),
    tap(async (action: PayloadAction<{ error: FindItError }>) => {
      const error = action?.payload?.error;
      if (error?.isError) {
        presentToast(
          `There was an error: ${error?.message}`,
          state$?.value?.appConfiguration?.debugMode,
          '#be123c'
        );
      }
    }),
    map(() => noOp({}))
  );

const epics: ((
  action$: Observable<Action<any>>,
  state$: StateObservable<AppState>
) => Observable<{
  payload: any;
  type: string;
}>)[] = [...appConfigurationEpics, ...gameEpics, failureEpic$];

const rootEpic = (
  action$: Observable<Action<any>>,
  state$: StateObservable<any>,
  dependencies: any
) => combineEpics(...epics)(action$, state$, dependencies);

const epicMiddleware = createEpicMiddleware();

export const store = configureStore({
  reducer: {
    appConfiguration: appConfigurationReducer,
    game: gameReducer,
  },
  middleware: [epicMiddleware],
});

epicMiddleware.run(rootEpic);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type FindItError = { message: string; stack: string; isError: boolean };
