import { Heading, HStack, Spinner } from 'native-base';
import { FC } from 'react';

export const Loader: FC<{}> = () => (
  <HStack space={2} alignItems="center">
    <Spinner
      color="secondary.500"
      accessibilityLabel="Loading game"
      size="sm"
    />
    <Heading fontSize="xl" color="primary.900">
      Loading
    </Heading>
  </HStack>
);
