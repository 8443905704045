import { Center, Text, View } from 'native-base';
import { FC } from 'react';
import { GameBoxCategory, GameDifficulty } from '../models/game.types';
import { ComposeNewCategoriesContent } from './compose-new-categories-content';
import { ComposeNewDifficultyContent } from './compose-new-difficulty-content';
import { Loader } from './loader';

export type ComposeNewContentProps = {
  contentIndex: number;
  categoriesCallback: (categories: GameBoxCategory[]) => void;
  difficultyCallback: (difficulty: GameDifficulty) => void;
  preSelectedCategories?: GameBoxCategory[];
  preselectedDifficulty?: GameDifficulty;
};
export const ComposeNewContent: FC<ComposeNewContentProps> = ({
  contentIndex,
  categoriesCallback,
  difficultyCallback,
  preSelectedCategories,
  preselectedDifficulty,
}: ComposeNewContentProps) => {
  switch (contentIndex) {
    case 0:
      return (
        <ComposeNewCategoriesContent
          categoriesCallback={categoriesCallback}
          preSelectedCategories={preSelectedCategories}
        />
      );
    case 1:
      return (
        <ComposeNewDifficultyContent
          difficultyCallback={difficultyCallback}
          preselectedDifficulty={preselectedDifficulty}
        />
      );
    case 2:
      return (
        <View height="100%">
          <Center>
            <Loader />
          </Center>
        </View>
      );
    default:
      return <Text>Something Went Wrong</Text>;
  }
};
