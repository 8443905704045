import {
  Modal,
  Button,
  Heading,
  VStack,
  ScrollView,
  Center,
  View,
} from 'native-base';
import { FC, lazy, useEffect, useRef, useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FirebaseGameBoxDocument,
  GameBox,
  GameBoxCategory,
} from '../../models/game.types';
import { RootState } from '../../state/store';
import { Loader } from '../loader';
import { setComposeNewCategoryModalVisibility } from '../../state/app/app-configuration-slice';
const CheckboxListScrollView = lazy(
  () => import('../checkbox-list-scroll-view')
);

export const ComposeNewCategoryListModal: FC = () => {
  const dispatch = useDispatch();
  const checkboxMetaData = useSelector(
    (state: RootState) => state.game.checkboxMetaData
  );
  const modalVisible = useSelector(
    (state: RootState) =>
      state?.appConfiguration?.composeNewCategoryModalVisible ?? false
  );
  const category = useSelector(
    (state: RootState) =>
      state?.appConfiguration?.composeNewCategoryModalCategory
  );
  const [displayedFindItItems, setDisplayedFindItItems] = useState<
    Array<FirebaseGameBoxDocument | GameBox>
  >([]);
  const scrollRef = useRef(null);
  useEffect(() => {
    if (category) {
      setDisplayedFindItItems(
        checkboxMetaData?.filter(
          (checkbox) => checkbox.category === category
        ) ?? []
      );
    }
    if (scrollRef != null && scrollRef.current != null) {
      scrollRef.current.scrollTo({ x: 0, y: 0, animated: true });
    }
  }, [category]);

  return (
    <Modal
      isOpen={modalVisible}
      onClose={() =>
        dispatch(setComposeNewCategoryModalVisibility({ visible: false }))
      }
      size="full"
      maxWidth={380}
      maxHeight={500}
      marginLeft="auto"
      marginRight="auto"
      alignContent="center"
    >
      <Modal.Content>
        <Modal.Body>
          <VStack space={1} maxHeight={400}>
            <Heading
              size="lg"
              textAlign="center"
              marginLeft="auto"
              marginRight="auto"
              _dark={{ color: 'primary.900' }}
              _light={{ color: 'secondary.500' }}
              alignSelf="flex-start"
            >
              {category}
            </Heading>
            <ScrollView
              flexGrow={1}
              ref={(ref) => {
                scrollRef.current = ref;
              }}
            >
              <Suspense
                fallback={
                  <View height="100%">
                    <Center>
                      <Loader />
                    </Center>
                  </View>
                }
              >
                <CheckboxListScrollView
                  displayedFindItItems={displayedFindItItems}
                />
              </Suspense>
            </ScrollView>
            <Button.Group
              alignSelf="flex-end"
              direction="column"
              width="full"
              size="full"
              maxWidth={500}
              _text={{ fontSize: 15 }}
            >
              <Button
                bg="gray.700"
                onPress={() =>
                  dispatch(
                    setComposeNewCategoryModalVisibility({ visible: false })
                  )
                }
                height={41}
              >
                Close Category Preview
              </Button>
            </Button.Group>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
