import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  Button,
  Center,
  FormControl,
  Heading,
  Input,
  VStack,
  WarningOutlineIcon,
} from 'native-base';
import { FC, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from '../components/loader';
import { RootStackParamList } from '../components/navigation';
import { StartNewGameWarningModal } from '../components/modals/start-new-game-warning-modal';
import { joinGame } from '../state/game/game-slice';
import { RootState } from '../state/store';

type JoinPageProps = NativeStackScreenProps<RootStackParamList, 'join'>;
export const JoinPage: FC<JoinPageProps> = ({ route, navigation }) => {
  const isLoading = useSelector((state: RootState) => state.game.loading);
  const [isInvalid, setIsInvalid] = useState(false);
  const [gameId, setGameId] = useState(route?.params?.gameId ?? '');
  const currentGameId = useSelector(
    (state: RootState) =>
      state?.appConfiguration?.currentUserData?.currentGameId
  );
  const originalGameId = useRef(currentGameId);
  useEffect(() => {
    if (
      currentGameId != null &&
      originalGameId.current != currentGameId &&
      gameId !== route?.params?.gameId
    ) {
      navigation.navigate('game', { gameId: currentGameId });
    }
  }, [currentGameId]);
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();
  const getResponse = (response: boolean) => {
    setModalVisible(false);
    if (response) {
      dispatch(joinGame({ gameId }));
    }
  };

  const onChangeText = (text: string) => {
    setGameId(text);
    setIsInvalid((text?.trim() ?? '') === '');
  };

  const submit = () => {
    if (isInvalid) {
      return;
    }
    if (currentGameId == null) {
      setModalVisible(true);
      return;
    }
    dispatch(joinGame({ gameId }));
  };
  return (
    <>
      <VStack alignItems="center" paddingLeft={5} paddingRight={5}>
        <Heading
          size="2xl"
          textAlign="center"
          _light={{ color: 'secondary.500' }}
          _dark={{ color: 'primary.900' }}
          marginBottom={5}
        >
          Join Game
        </Heading>
        <FormControl isInvalid={isInvalid} maxW={500} marginBottom={5}>
          <FormControl.Label>Game Code</FormControl.Label>
          <Input
            value={gameId}
            placeholder="Enter game code"
            onChangeText={(text) => onChangeText(text)}
          />
          <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
            Please enter a valid game code
          </FormControl.ErrorMessage>
        </FormControl>
        <Button.Group
          direction="column"
          width="full"
          maxWidth={500}
          _text={{ fontSize: 15 }}
        >
          <Button
            disabled={isInvalid}
            _dark={{ bg: 'primary.900' }}
            _light={{ bg: 'secondary.500' }}
            onPress={() => {
              if (isInvalid) {
                return;
              }
              submit();
            }}
          >
            Join Game
          </Button>
        </Button.Group>
      </VStack>
      {isLoading ? (
        <Center>
          <Loader />
        </Center>
      ) : null}
      <StartNewGameWarningModal
        modalVisible={modalVisible}
        getResponse={getResponse}
      />
    </>
  );
};
