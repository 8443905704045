import {
  Text,
  VStack,
  Heading,
  Center,
  Progress,
  HStack,
  View,
  ScrollView,
  Button,
} from 'native-base';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { FC, useEffect, useRef, useState, lazy, Suspense } from 'react';
import { RootStackParamList } from '../components/navigation';
import {
  getCheckboxesForGame,
  subscribeToCheckboxChanges,
  subscribeToGameChanges,
} from '../game-utilities';
import { FirebaseGameBoxDocument, GameBox, IGame } from '../models/game.types';
import {
  Unsubscribe,
  DocumentSnapshot,
  DocumentData,
  QuerySnapshot,
} from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../state/store';
import {
  setCheckboxes,
  setEndGameModalVisibility,
  setGame,
  setInviteOthersModalVisibility,
} from '../state/game/game-slice';
import { Loader } from '../components/loader';
import React from 'react';
const Categories = lazy(() => import('../components/categories'));

type GamePageProps = NativeStackScreenProps<RootStackParamList, 'game'>;

export const GamePage: FC<GamePageProps> = ({ route, navigation }) => {
  const dispatch = useDispatch();
  const currentUserIdInStore = useSelector(
    (state: RootState) => state?.appConfiguration?.currentUser?.uid
  );

  const [game, setComponentGame] = useState<IGame | null>(null);

  const gameUnsubscribeRef = useRef<Unsubscribe | null>(null);
  const gameCheckboxes = useSelector(
    (state: RootState) => state.game.checkboxes
  );
  useEffect(() => {
    if (gameUnsubscribeRef.current != null) {
      gameUnsubscribeRef.current();
    }
    if (checkboxesUnsubscribeRef.current != null) {
      checkboxesUnsubscribeRef.current();
    }
    gameUnsubscribeRef.current = subscribeToGameChanges(
      route.params.gameId,
      (snapshot: DocumentSnapshot<DocumentData>) => {
        const game = snapshot.data() as IGame;
        const gameId = snapshot.id;
        setComponentGame(game);
        dispatch(setGame({ game, gameId }));
      }
    );
    checkboxesUnsubscribeRef.current = subscribeToCheckboxChanges(
      route.params.gameId,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const checkboxes = snapshot.docs.map(
          (doc) => doc.data() as FirebaseGameBoxDocument | GameBox
        );
        const totalCheckboxesChecked = checkboxes.filter(
          (checkbox) => checkbox.checked
        ).length;
        if (totalCheckboxesChecked !== totalCheckboxesCheckedRef.current) {
          totalCheckboxesCheckedRef.current = totalCheckboxesChecked;
          setTotalCheckboxesChecked(totalCheckboxesChecked);
        }
      }
    );
    if ((gameCheckboxes?.length ?? 0) === 0) {
      getCheckboxesForGame(route.params.gameId)
        .then((checkboxes) => dispatch(setCheckboxes({ checkboxes })))
        .catch((error) => console.log(error));
    }
    return () => {
      if (gameUnsubscribeRef.current != null) {
        gameUnsubscribeRef.current();
      }
    };
  }, [route.params.gameId]);

  useEffect(() => {
    if (game?.gameArchivedDate != null) {
      navigation.navigate('home');
    }
  }, [game?.gameArchivedDate]);

  const checkboxDictionary = gameCheckboxes.reduce((acc, checkbox) => {
    if (
      acc[checkbox.category] &&
      (checkbox as FirebaseGameBoxDocument)?.gameId != null
    ) {
      acc[checkbox.category].push(checkbox as FirebaseGameBoxDocument);
    } else if ((checkbox as FirebaseGameBoxDocument)?.gameId != null) {
      acc[checkbox.category] = [checkbox as FirebaseGameBoxDocument];
    }
    return acc;
  }, {} as { [key: string]: FirebaseGameBoxDocument[] });
  const totalCheckboxes = gameCheckboxes.length;
  const [totalCheckboxesChecked, setTotalCheckboxesChecked] =
    useState<number>(totalCheckboxes);
  const totalCheckboxesCheckedRef = useRef<number>(totalCheckboxesChecked);
  const checkboxesUnsubscribeRef = useRef<Unsubscribe | null>(null);

  return (
    <ScrollView>
      <Center padding={5}>
        {game != null && game?.gameArchivedDate != null ? (
          <></>
        ) : (
          <VStack alignItems="center" width="full" maxWidth={500}>
            <Heading
              _light={{ color: 'secondary.500' }}
              _dark={{ color: 'primary.900' }}
              textAlign="center"
            >
              Game Started, happy hunting!
            </Heading>
            <HStack width="full">
              <Progress
                flexGrow={1}
                value={
                  ((totalCheckboxesChecked ?? 0) /
                    (totalCheckboxes === 0 ? 1 : totalCheckboxes)) *
                  100
                }
                marginRight={5}
                _light={{ _filledTrack: { bg: 'secondary.500' } }}
                _dark={{ _filledTrack: { bg: 'primary.900' } }}
                alignSelf="center"
                alignContent="center"
              />
              <Text>
                {`${totalCheckboxesChecked ?? 0}`} / {`${totalCheckboxes}`}
              </Text>
            </HStack>
            <VStack width="full">
              <Suspense
                fallback={
                  <View height="100%">
                    <Center>
                      <Loader />
                    </Center>
                  </View>
                }
              >
                <Categories checkboxDictionary={checkboxDictionary} />
              </Suspense>
            </VStack>
            <Button.Group
              marginTop={5}
              direction="column"
              width="full"
              _text={{ fontSize: 15 }}
            >
              <Button
                bg="gray.700"
                onPress={() =>
                  dispatch(setInviteOthersModalVisibility({ visible: true }))
                }
              >
                Invite others to Play
              </Button>
              {(game?.createdBy != null &&
                game?.createdBy === currentUserIdInStore) ||
              ((game?.playerIds?.length ?? 0) > 0 &&
                game?.playerIds[0] === currentUserIdInStore) ? (
                <Button
                  bg="gray.700"
                  onPress={() =>
                    dispatch(setEndGameModalVisibility({ visible: true }))
                  }
                >
                  End Game
                </Button>
              ) : (
                <Button bg="gray.700" isDisabled={true}>
                  End Game
                </Button>
              )}
            </Button.Group>
          </VStack>
        )}
      </Center>
    </ScrollView>
  );
};
