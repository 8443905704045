import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Box, View } from 'native-base';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../components/loader';
import { RootStackParamList } from '../components/navigation';
import { StartNewGameWarningModal } from '../components/modals/start-new-game-warning-modal';
import { loadCheckboxMetaData } from '../state/game/game-slice';
import { RootState } from '../state/store';

type StartNewPageProps = NativeStackScreenProps<RootStackParamList, 'startnew'>;
export const StartNewPage: FC<StartNewPageProps> = ({ route, navigation }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.game.loading);
  const game = useSelector((state: RootState) => state?.game?.game);
  useEffect(() => {
    if (game != null && game?.gameArchivedDate == null) {
      setModalVisible(true);
    } else {
      dispatch(loadCheckboxMetaData({}));
      navigation.navigate('composegame');
    }
  }, []);
  const [modalVisible, setModalVisible] = useState(
    game != null && game?.gameArchivedDate == null
  );
  const getResponse = (response: boolean) => {
    setModalVisible(false);
    if (!response) {
      navigation.navigate('home');
    } else {
      dispatch(loadCheckboxMetaData({}));
      navigation.navigate('composegame');
    }
  };

  return (
    <View justifyContent="center" alignItems="center" height="100%">
      <Box>start new</Box>
      {isLoading ? <Loader /> : null}
      <StartNewGameWarningModal
        modalVisible={modalVisible}
        getResponse={getResponse}
      />
    </View>
  );
};
