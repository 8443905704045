import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  Spacer,
  Text,
  VStack,
} from 'native-base';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ComposeNewContent } from '../components/compose-new-content';
import { RootStackParamList } from '../components/navigation';
import { GameBoxCategory, GameDifficulty } from '../models/game.types';
import { RootState } from '../state/store';
import { startNewGame } from '../state/game/game-slice';
import { Loader } from '../components/loader';

type ComposeGamePageProps = NativeStackScreenProps<
  RootStackParamList,
  'composegame'
>;
export const ComposeGamePage: FC<ComposeGamePageProps> = ({
  route,
  navigation,
}) => {
  const dispatch = useDispatch();
  const [contentIndex, setContentIndex] = useState(0);
  const [atLeasOneCategory, setAtLeastOneCategory] = useState(false);
  const categories = useRef<GameBoxCategory[]>([]);
  const categoriesCallback = (callbackCategories: GameBoxCategory[]) => {
    if (callbackCategories.length > 0 && !atLeasOneCategory) {
      setAtLeastOneCategory(true);
    }
    if (callbackCategories.length === 0 && atLeasOneCategory) {
      setAtLeastOneCategory(false);
    }
    categories.current = callbackCategories;
  };
  const difficulty = useRef<GameDifficulty>(GameDifficulty.Easy);
  const difficultyCallback = (callbackDifficulty: GameDifficulty) =>
    (difficulty.current = callbackDifficulty);

  const loading = useSelector((state: RootState) => state.game.loading);

  const nextButtonHandler = () => {
    if (contentIndex > 1) {
      return;
    }
    if (contentIndex === 1 && atLeasOneCategory && difficulty.current != null) {
      dispatch(
        startNewGame({
          categories: categories.current,
          difficulty: difficulty.current,
        })
      );
    }
    setContentIndex(contentIndex + 1);
  };

  const backButtonHandler = () => {
    if (contentIndex <= 0) {
      return;
    }
    setContentIndex(contentIndex - 1);
  };
  const currentGameId = useSelector(
    (state: RootState) =>
      state?.appConfiguration?.currentUserData?.currentGameId
  );
  const originalGameId = useRef(currentGameId);
  useEffect(() => {
    if (currentGameId != null && originalGameId.current != currentGameId) {
      navigation.navigate('game', { gameId: currentGameId });
    }
  }, [currentGameId]);
  return (
    <>
      <VStack
        alignItems="center"
        paddingLeft={5}
        paddingRight={5}
        marginLeft="auto"
        marginRight="auto"
        justifyContent="space-between"
        flexDirection="column"
        width="full"
        maxWidth={500}
        height="full"
      >
        <Box
          flexShrink={1}
          alignItems="flex-start"
          width="full"
          paddingTop={5}
          paddingBottom={5}
        >
          <Heading
            size="xl"
            textAlign="center"
            _light={{ color: 'secondary.500' }}
            _dark={{ color: 'primary.900' }}
            marginLeft="auto"
            marginRight="auto"
          >
            {contentIndex === 0 ? 'Choose Categories' : 'Select Difficulty'}
          </Heading>
          <Divider
            marginTop={1}
            _light={{ bg: 'secondary.500' }}
            _dark={{ bg: 'primary.900' }}
          />
        </Box>
        <Box
          flexGrow={1}
          alignItems="flex-start"
          width="full"
          paddingBottom={5}
        >
          <ComposeNewContent
            contentIndex={contentIndex}
            categoriesCallback={categoriesCallback}
            difficultyCallback={difficultyCallback}
            preSelectedCategories={categories.current}
            preselectedDifficulty={difficulty.current}
          />
        </Box>
        <Spacer />
        <Box
          flexShrink={1}
          alignItems="flex-end"
          width="full"
          paddingBottom={5}
        >
          <Button.Group
            direction="column"
            width="full"
            _text={{ fontSize: 15 }}
          >
            <Button
              _dark={{ bg: 'primary.900' }}
              _light={{ bg: 'secondary.500' }}
              onPress={() => nextButtonHandler()}
              isDisabled={
                (contentIndex === 1 &&
                  (difficulty.current == null || !atLeasOneCategory)) ||
                (contentIndex === 0 && !atLeasOneCategory) ||
                loading
              }
            >
              Next
            </Button>
            <Button
              bg="gray.700"
              onPress={() => backButtonHandler()}
              isDisabled={contentIndex === 0 || loading}
            >
              Back
            </Button>
            <Button
              disabled={loading}
              bg="gray.700"
              onPress={() => navigation.navigate('home')}
            >
              Cancel
            </Button>
          </Button.Group>
        </Box>
      </VStack>
    </>
  );
};
