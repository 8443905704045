import {
  Action,
  AnyAction,
  PayloadAction,
  createSlice,
} from '@reduxjs/toolkit';
import { AppConfigurationState } from './app-configuration-types';
import { ofType, StateObservable } from 'redux-observable';
import { catchError, map, mergeMap, Observable, tap, of, from } from 'rxjs';
import * as SecureStore from 'expo-secure-store';
import { ColorMode } from 'native-base';
import { FindItError, RootState } from '../store';
import {
  getItemFromStorageAsync,
  setItemInStorageAsync,
} from '../../firebase-utilities';
import { User } from 'firebase/auth';
import { FindItUser } from '../../models/game.types';

const initialAppConfigurationState: AppConfigurationState = {
  debugMode: false,
  currentUser: null,
  currentUserData: null,
  darkMode: false,
  error: null,
  driverWarningModalVisible: true,
  composeNewCategoryModalVisible: false,
  resetPasswordModalVisible: false,
  composeNewCategoryModalCategory: null,
};

export const appConfigurationSlice = createSlice({
  name: 'appConfiguration',
  initialState: initialAppConfigurationState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<{ user: User }>) => {
      console.log(
        'action setCurrentUser called with state, and action',
        state,
        action
      );
      const { user } = action.payload;
      state.currentUser = user;
    },
    setCurrentUserData: (
      state,
      action: PayloadAction<{ userData: FindItUser }>
    ) => {
      console.log(
        'action setCurrentUserData called with state, and action',
        state,
        action
      );
      const { userData } = action.payload;
      state.currentUserData = userData;
    },
    loadDebugMode: (state, action: PayloadAction<{}>) => {
      console.log(
        'action loadDebugMode called with state, and action',
        state,
        action
      );
      return state;
    },
    loadDebugModeSuccess: (
      state,
      action: PayloadAction<{ debugMode: boolean }>
    ) => {
      console.log(
        'action loadDebugModeSuccess called with state, and action',
        state,
        action
      );
      const { debugMode } = action.payload;
      state.debugMode = debugMode;
      state.error = null;
    },
    loadDebugModeFailure: (
      state,
      action: PayloadAction<{ error: FindItError }>
    ) => {
      console.log(
        'action loadDebugModeFailure called with state, and action',
        state,
        action
      );
      const { error } = action.payload;
      state.error = error;
    },
    setDebugMode: (state, action: PayloadAction<{ debugMode: boolean }>) => {
      console.log(
        'action setDebugMode called with state, and action',
        state,
        action
      );
      const { debugMode } = action.payload;
      state.debugMode = debugMode;
    },
    setDriverWarningModalVisible: (
      state,
      action: PayloadAction<{ visible: boolean }>
    ) => {
      console.log(
        'action setDriverWarningModalVisible called with state, and action',
        state,
        action
      );
      const { visible } = action.payload;
      state.driverWarningModalVisible = visible;
      return state;
    },
    setComposeNewCategoryModalVisibility: (
      state,
      action: PayloadAction<{ visible: boolean }>
    ) => {
      console.log(
        'action setComposeNewCategoryModalVisibility called with state, and action',
        state,
        action
      );
      const { visible } = action.payload;
      state.composeNewCategoryModalVisible = visible;
      return state;
    },
    setComposeNewCategoryModalCategory: (
      state,
      action: PayloadAction<{ category: string }>
    ) => {
      console.log(
        'action setComposeNewCategoryModalCategory called with state, and action',
        state,
        action
      );
      const { category } = action.payload;
      state.composeNewCategoryModalCategory = category;
      return state;
    },
    setResetPasswordModalVisibility(
      state,
      action: PayloadAction<{ visible: boolean }>
    ) {
      console.log(
        'action setComposeNewCategoryModalresetPasswordModalVisibility called with state, and action',
        state,
        action
      );
      const { visible } = action.payload;
      state.resetPasswordModalVisible = visible;
      return state;
    },
    noOp: (state, action) => {
      console.log('action noOp called with state, and action', state, action);
      return state;
    },
  },
});

export const {
  setCurrentUser,
  setCurrentUserData,
  loadDebugMode,
  loadDebugModeSuccess,
  loadDebugModeFailure,
  setDebugMode,
  setDriverWarningModalVisible,
  setComposeNewCategoryModalVisibility,
  setComposeNewCategoryModalCategory,
  setResetPasswordModalVisibility,
  noOp,
} = appConfigurationSlice.actions;

export const appConfigurationReducer = appConfigurationSlice.reducer;

export const loadDebugModeEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(loadDebugMode.type),
    mergeMap(() =>
      from(getItemFromStorageAsync('findit-app-debug-mode')).pipe(
        catchError((error) => {
          console.error('error in loadDebugModeEpic$', error);
          return of({
            message: error.message,
            stack: error.stack,
            isError: true,
          } as FindItError);
        })
      )
    ),
    map((response: string | FindItError) =>
      (response as FindItError)?.isError ?? false
        ? loadDebugModeFailure({ error: response as FindItError })
        : loadDebugModeSuccess({ debugMode: response === 'true' })
    )
  );

export const setDebugModeEpic$ = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(setDebugMode.type),
    mergeMap((action) =>
      from(
        setItemInStorageAsync(
          'findit-app-debug-mode',
          action.payload?.debugMode?.toString() ?? true.valueOf().toString()
        )
      ).pipe(
        catchError((error) => {
          console.error('error in setDebugModeEpic$', error);
          return of({
            message: error.message,
            stack: error.stack,
            isError: true,
          } as FindItError);
        })
      )
    ),
    map(() => noOp({}))
  );

export const appConfigurationEpics = [loadDebugModeEpic$, setDebugModeEpic$];
