import { Button, Heading, Text, VStack, Image } from 'native-base';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList, navigationRef } from '../components/navigation';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../state/store';
import { fireAuth } from '../backend';
import { signOut } from 'firebase/auth';
import { getGame } from '../game-utilities';
import { setGame } from '../state/game/game-slice';
import { setDebugMode } from '../state/app/app-configuration-slice';

type HomePageProps = NativeStackScreenProps<RootStackParamList, 'home'>;
export const HomePage: FC<HomePageProps> = ({
  route,
  navigation,
}: HomePageProps) => {
  const dispatch = useDispatch();
  const currentGameId = useSelector(
    (state: RootState) =>
      state?.appConfiguration?.currentUserData?.currentGameId
  );
  const currentGame = useSelector((state: RootState) => state?.game?.game);
  const currentGameGameId = useSelector(
    (state: RootState) => state?.game?.gameId
  );

  const currentDebugMode = useSelector(
    (state: RootState) => state?.appConfiguration?.debugMode
  );

  const [labelPressedCount, setLabelPressedCount] = useState(0);

  const handleLabelPress = () => {
    if (labelPressedCount === 20) {
      dispatch(
        setDebugMode({ debugMode: currentDebugMode === false ? true : false })
      );
      setLabelPressedCount(0);
    }
    setLabelPressedCount((prev) => prev + 1);
  };

  useEffect(() => {
    if (
      currentGameId != null &&
      (currentGame == null || currentGameGameId != currentGameId)
    ) {
      const gameId = currentGameId;
      getGame(currentGameId)
        .then((game) => dispatch(setGame({ game, gameId })))
        .catch((err) => console.error('error getting game', err));
    }
  }, [currentGameId]);
  return (
    <VStack alignItems="center" paddingLeft={5} paddingRight={5}>
      <Heading
        size="2xl"
        textAlign="center"
        _light={{ color: 'secondary.500' }}
        _dark={{ color: 'primary.900' }}
        paddingTop={5}
        onPress={() => handleLabelPress()}
      >
        FIND IT!
      </Heading>
      <Text textAlign="center">The Scavenger Hunt game for car rides!</Text>
      <Image
        height={300}
        width="full"
        maxWidth={500}
        marginBottom={5}
        marginTop={5}
        resizeMode="cover"
        source={require('../assets/home-page-image.png')}
        alt="Person with binoculars"
      />
      <Button.Group
        direction="column"
        width="full"
        maxWidth={500}
        _text={{ fontSize: 15 }}
      >
        <Button
          _dark={{ bg: 'primary.900' }}
          _light={{ bg: 'secondary.500' }}
          onPress={() => navigation.navigate('startnew')}
        >
          Start New Game
        </Button>
        <Button
          _light={{ bg: currentGameId == null ? 'primary.900' : 'primary.900' }}
          _dark={{
            bg: currentGameId == null ? 'secondary.500' : 'secondary.500',
          }}
          isDisabled={
            currentGameId == null || currentGame?.gameArchivedDate != null
          }
          onPress={() => {
            if (currentGameId == null) {
              return;
            }
            navigation.navigate('game', { gameId: currentGameId });
          }}
        >
          Continue Game
        </Button>
        <Button
          _light={{ bg: 'primary.900' }}
          _dark={{ bg: 'secondary.500' }}
          onPress={() => navigation.navigate('join')}
        >
          Join Game
        </Button>
      </Button.Group>
    </VStack>
  );
};
