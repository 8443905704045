import { MaterialIcons } from '@expo/vector-icons';
import { Modal, Button, Icon, Heading, VStack, Center } from 'native-base';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { useDispatch } from 'react-redux';
import { setDriverWarningModalVisible } from '../../state/app/app-configuration-slice';

export interface DriverWarningModalProps {}

export const DriverWarningModal: FC<DriverWarningModalProps> = ({}) => {
  const dispatch = useDispatch();
  const modalVisible = useSelector(
    (state: RootState) =>
      state?.appConfiguration?.driverWarningModalVisible ?? false
  );
  return (
    <Modal
      isOpen={modalVisible}
      onClose={() => dispatch(setDriverWarningModalVisible({ visible: false }))}
      size="full"
      maxWidth={380}
      marginLeft="auto"
      marginRight="auto"
      alignContent="center"
    >
      <Modal.Content maxH="600">
        <Modal.Body>
          <VStack
            space={1}
            alignItems="center"
            paddingLeft={5}
            paddingRight={5}
          >
            <Heading
              size="sm"
              textAlign="center"
              _dark={{ color: 'primary.900' }}
              _light={{ color: 'secondary.500' }}
            >
              Find It! Shoud not be played while driving
            </Heading>
            <Icon
              size="200"
              as={MaterialIcons}
              name="taxi-alert"
              _dark={{ color: 'primary.900' }}
              _light={{ color: 'secondary.500' }}
            />
            <Button.Group width="full" size="full" _text={{ fontSize: 15 }}>
              <Button
                _dark={{ bg: 'primary.900' }}
                _light={{ bg: 'secondary.500' }}
                onPress={() =>
                  dispatch(setDriverWarningModalVisible({ visible: false }))
                }
                height={41}
              >
                OK, I agree
              </Button>
            </Button.Group>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
