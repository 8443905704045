import Constants from 'expo-constants';
const schemaVersion = Constants?.expoConfig?.extra?.schemaVersion;
import {
  arrayRemove,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  runTransaction,
  where,
  DocumentData,
  arrayUnion,
  onSnapshot,
  DocumentSnapshot,
  Unsubscribe,
  QuerySnapshot,
  updateDoc,
  Timestamp,
  serverTimestamp,
} from 'firebase/firestore';
import { fireAuth, fireStore } from './backend';
import { Game } from './models/game.model';
import {
  FindItUser,
  FirebaseGame,
  FirebaseGameBoxDocument,
  GameBox,
  GameBoxCategory,
  GameDifficulty,
  IGame,
} from './models/game.types';

export const startNewGameInFirebase = async (
  categories: GameBoxCategory[],
  difficulty: GameDifficulty
): Promise<{
  game: DocumentData;
  checkboxes: DocumentData;
}> => {
  try {
    const gamesCollectionRef = collection(fireStore, `games${schemaVersion}`);
    const usersCollectionRef = collection(fireStore, `users${schemaVersion}`);
    const checkboxesCollectionRef = collection(
      fireStore,
      `checkboxes${schemaVersion}`
    );
    const currentUser = fireAuth.currentUser;
    if (currentUser?.uid == null) {
      throw new Error('User is not logged in');
    }
    let gameId: string = null;
    await runTransaction(fireStore, async (transaction) => {
      const newGameDocumentRef = doc(gamesCollectionRef);
      const gameObject = await Game.createGameAsync(
        fireStore,
        currentUser.uid,
        [{ userId: currentUser.uid, secondsPlayed: 0 }],
        null,
        categories,
        difficulty
      );
      transaction.set(
        newGameDocumentRef,
        gameObject.asFirebaseCollectionInterface()
      );
      const userDocRef = doc(usersCollectionRef, currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      const currentUserDocData = userDoc.data() as FindItUser;
      if (currentUserDocData?.currentGameId != null) {
        const previousUserGameDoc = doc(
          gamesCollectionRef,
          currentUserDocData.currentGameId
        );
        const previousUserGameData = await getDoc(previousUserGameDoc);
        if (
          previousUserGameData.exists() &&
          previousUserGameData.data()?.gameArchivedDate == null
        ) {
          const previousUserPlayerEntry = previousUserGameData
            .data()
            .players.find((player) => player.userId === currentUser.uid);
          transaction.update(previousUserGameDoc, {
            players: arrayRemove({ ...previousUserPlayerEntry }),
            playerIds: arrayRemove(currentUser.uid),
          });
        }
      }
      transaction.update(userDocRef, {
        currentGameId: newGameDocumentRef.id,
      });
      gameObject.checkBoxes.forEach((checkbox) => {
        const checkboxDocumentRef = doc(
          checkboxesCollectionRef,
          `${checkbox.id}_${newGameDocumentRef.id}`
        );
        const modifiedCheckbox = checkbox as FirebaseGameBoxDocument;
        modifiedCheckbox.gameId = newGameDocumentRef.id;
        transaction.set(checkboxDocumentRef, modifiedCheckbox);
      });
      gameId = newGameDocumentRef.id;
    });
    const response = await getGameAndCheckboxes(gameId);
    return response;
  } catch (e) {
    console.log('startNewGame failed with', e);
    throw e;
  }
};

export const joinGameInFirebase = async (
  gameId: string,
  userId: string
): Promise<{
  game: IGame;
  checkboxes: GameBox[];
}> => {
  try {
    const gamesCollectionRef = collection(fireStore, `games${schemaVersion}`);
    const documentRef = doc(fireStore, `games${schemaVersion}`, gameId);
    const usersCollectionRef = collection(fireStore, `users${schemaVersion}`);
    await runTransaction(fireStore, async (transaction) => {
      const documentSnapshot = await transaction.get(documentRef);
      if (!documentSnapshot.exists()) {
        throw new Error('Game not found');
      }
      if (documentSnapshot.data().gameArchivedDate != null) {
        throw new Error('Cannot join a completed game!');
      }
      const currentUser = fireAuth.currentUser;
      const userDoc = doc(usersCollectionRef, currentUser.uid);
      const userData = await getDoc(userDoc);
      if (userData.data().currentGameId != null) {
        const previousUserGameDoc = doc(
          gamesCollectionRef,
          userData.data().currentGameId
        );
        const previousUserGameData = await getDoc(previousUserGameDoc);
        if (
          (previousUserGameData.data()?.playerIds?.length ?? 0) > 1 &&
          (previousUserGameData.data()?.createdBy === userId ||
            previousUserGameData.data()?.playerIds?.at(0) === userId)
        ) {
          transaction.update(previousUserGameDoc, {
            gameArchivedDate: serverTimestamp(),
          });
        } else {
          if (
            previousUserGameData.exists() &&
            previousUserGameData.data()?.gameArchivedDate == null
          ) {
            const previousUserPlayerEntry = previousUserGameData
              .data()
              .players.find((player) => player.userId === currentUser.uid);
            transaction.update(previousUserGameDoc, {
              players: arrayRemove({ ...previousUserPlayerEntry }),
              playerIds: arrayRemove(currentUser.uid),
            });
          }
        }
      }
      const addedPlayer = { userId, secondsPlayed: 0 };
      transaction.update(documentRef, {
        players: arrayUnion(addedPlayer),
        playerIds: arrayUnion(addedPlayer.userId),
      });
      const userRef = doc(fireStore, `users${schemaVersion}`, userId);
      transaction.update(userRef, {
        currentGameId: gameId,
      });
    });
    const response = await getGameAndCheckboxes(gameId);
    return response;
  } catch (e) {
    console.log('continueGame failed with', e);
    throw e;
  }
};

const getGameAndCheckboxes = async (
  gameId: string
): Promise<{ game: IGame; checkboxes: GameBox[] }> => {
  const gamesCollectionRef = collection(fireStore, `games${schemaVersion}`);
  const checkboxesCollectionRef = collection(
    fireStore,
    `checkboxes${schemaVersion}`
  );
  const gameDocRef = doc(gamesCollectionRef, gameId);
  const gameDoc = await getDoc(gameDocRef);
  const game = gameDoc.data() as IGame;
  const checkboxesQuery = query(
    checkboxesCollectionRef,
    where('gameId', '==', gameId)
  );
  const checkboxDocs = await getDocs(checkboxesQuery);
  const checkboxes = checkboxDocs.docs.map((doc) => doc.data() as GameBox);
  return { game, checkboxes };
};

export const getGame = async (gameId: string): Promise<IGame> => {
  const gamesCollectionRef = collection(fireStore, `games${schemaVersion}`);
  const gameDocRef = doc(gamesCollectionRef, gameId);
  const gameDoc = await getDoc(gameDocRef);
  const game = gameDoc.data() as IGame;
  return game;
};

export const getCheckboxesForGame = async (
  gameId: string
): Promise<GameBox[]> => {
  try {
    const checkboxesCollectionRef = collection(
      fireStore,
      `checkboxes${schemaVersion}`
    );
    const checkboxesQuery = query(
      checkboxesCollectionRef,
      where('gameId', '==', gameId)
    );
    const checkboxDocs = await getDocs(checkboxesQuery);
    const checkboxes = checkboxDocs.docs.map((doc) => doc.data() as GameBox);
    return checkboxes;
  } catch (e) {
    return [];
  }
};

export const subscribeToGameChanges = (
  gameId: string,
  callback: (snapshot: DocumentSnapshot<DocumentData>) => void
): Unsubscribe => {
  const gamesCollectionRef = collection(fireStore, `games${schemaVersion}`);
  const gameDocRef = doc(gamesCollectionRef, gameId);
  return onSnapshot(gameDocRef, callback);
};

export const subscribeToSingleCheckboxChanges = (
  checkboxId: string,
  gameId: string,
  callback: (snapshot: DocumentSnapshot<DocumentData>) => void
): Unsubscribe => {
  const gamesCollectionRef = collection(
    fireStore,
    `checkboxes${schemaVersion}`
  );
  const gameDocRef = doc(gamesCollectionRef, `${checkboxId}_${gameId}`);
  return onSnapshot(gameDocRef, callback);
};

export const subscribeToCheckboxChanges = (
  gameId: string,
  callback: (snapshot: QuerySnapshot<DocumentData>) => void
): Unsubscribe => {
  const checkboxesCollectionRef = collection(
    fireStore,
    `checkboxes${schemaVersion}`
  );
  const checkboxesQueryRef = query(
    checkboxesCollectionRef,
    where('gameId', '==', gameId)
  );
  return onSnapshot(checkboxesQueryRef, callback);
};

export const setCheckboxCheckedStatusInFirebase = async (
  checkboxId: string,
  gameId: string,
  checked: boolean
): Promise<void> => {
  const checkboxesCollectionRef = collection(
    fireStore,
    `checkboxes${schemaVersion}`
  );
  const checkboxDocRef = doc(
    checkboxesCollectionRef,
    `${checkboxId}_${gameId}`
  );
  await updateDoc(checkboxDocRef, { checked });
};

export const setCheckboxesCheckedStatusesInFirebase = async (
  checkboxes: Array<{
    checkboxId: string;
    gameId: string;
    checked: boolean;
  }>
): Promise<void> => {
  if (checkboxes.length === 0) {
    return;
  }
  await runTransaction(fireStore, async (transaction) => {
    const checkboxesCollectionRef = collection(
      fireStore,
      `checkboxes${schemaVersion}`
    );
    for (let checkbox of checkboxes) {
      const { checkboxId, gameId, checked } = checkbox;
      const checkboxDocRef = doc(
        checkboxesCollectionRef,
        `${checkboxId}_${gameId}`
      );
      transaction.update(checkboxDocRef, {
        checked,
      });
    }
  });
};

export const archiveGame = async (
  gameId: string
): Promise<{
  game: IGame;
  checkboxes: GameBox[];
}> => {
  const gamesCollectionRef = collection(fireStore, `games${schemaVersion}`);
  const gameDocRef = doc(gamesCollectionRef, gameId);
  await updateDoc(gameDocRef, { gameArchivedDate: serverTimestamp() });
  return await getGameAndCheckboxes(gameId);
};

export type GameHistory = {
  gameNumber: number;
  startDate: Date;
  gameTimeSeconds: number;
  itemsFound: number;
  totalItems: number;
};

export type GameHistoryForUser = {
  gamesPlayed: number;
  totalPlayTimeSeconds: number;
  totalItemsFound: number;
  totalItems: number;
  gameHistories: GameHistory[];
};

export const getGameHistoryForUserId = async (
  userId: string
): Promise<GameHistoryForUser> => {
  const gamesCollectionRef = collection(fireStore, `games${schemaVersion}`);
  const gamesForUserQuery = query(
    gamesCollectionRef,
    where('playerIds', 'array-contains', userId),
    where('gameArchivedDate', '!=', null)
  );
  const games = await getDocs(gamesForUserQuery);
  const archivedGames = games.docs
    .map(
      (doc) => ({ ...(doc.data() as IGame), gameId: doc.id } as FirebaseGame)
    )
    .sort(
      (left, right) => left.createdDate.seconds - right.createdDate.seconds
    );
  const gameIds = archivedGames.map((game) => game.gameId);
  const checkboxesCollectionRef = collection(
    fireStore,
    `checkboxes${schemaVersion}`
  );
  const checkboxesQuery = query(
    checkboxesCollectionRef,
    where('gameId', 'in', gameIds)
  );
  const archivedGameCheckboxDocs = await getDocs(checkboxesQuery);
  const archivedGameCheckboxes = archivedGameCheckboxDocs.docs.map(
    (doc) => doc.data() as FirebaseGameBoxDocument
  );
  const gameIdToCheckboxDictionary = archivedGameCheckboxes.reduce(
    (acc, checkbox) => {
      if (acc[checkbox.gameId] && checkbox?.gameId != null) {
        acc[checkbox.gameId].push(checkbox);
      } else if (checkbox?.gameId != null) {
        acc[checkbox.gameId] = [checkbox];
      }
      return acc;
    },
    {} as { [key: string]: FirebaseGameBoxDocument[] }
  );
  return createGameHistory(archivedGames, gameIdToCheckboxDictionary);
};

const createGameHistory = (
  games: FirebaseGame[],
  gameIdToCheckboxDictionary: {
    [key: string]: FirebaseGameBoxDocument[];
  }
): GameHistoryForUser => {
  let totalPlayTimeSeconds = 0;
  let gamesPlayed = 0;
  let totalItemsFound = 0;
  let totalItems = 0;
  const gameHistories = games.map((archivedGame, gameNumber) => {
    gamesPlayed++;
    const { createdDate, gameArchivedDate } = archivedGame;
    const createdDateAsDate = createdDate.toDate();
    const gameTimeSeconds = gameArchivedDate.seconds - createdDate.seconds;
    totalPlayTimeSeconds += gameTimeSeconds;
    const checkboxes = gameIdToCheckboxDictionary[
      archivedGame.gameId
    ] as FirebaseGameBoxDocument[];
    const itemsFound = checkboxes.filter((checkbox) => checkbox.checked).length;
    totalItemsFound += itemsFound;
    const items = checkboxes.length;
    totalItems += items;
    return {
      gameNumber: gameNumber + 1,
      startDate: createdDateAsDate,
      gameTimeSeconds,
      itemsFound,
      totalItems: items,
    } as GameHistory;
  });

  return {
    gamesPlayed,
    totalPlayTimeSeconds,
    totalItemsFound,
    totalItems,
    gameHistories,
  };
};
