import { Box, Radio, Spacer, Text } from 'native-base';
import { FC, useState } from 'react';
import { GameDifficulty } from '../models/game.types';

export type ComposeNewDifficultyContentProps = {
  difficultyCallback: (difficulty: GameDifficulty) => void;
  preselectedDifficulty?: GameDifficulty;
};
export const ComposeNewDifficultyContent: FC<
  ComposeNewDifficultyContentProps
> = ({ difficultyCallback, preselectedDifficulty }) => {
  const listData = [
    GameDifficulty.Easy,
    GameDifficulty.Medium,
    GameDifficulty.Hard,
  ];
  const [currentDifficulty, setCurrentDifficulty] = useState(
    preselectedDifficulty ?? GameDifficulty.Easy
  );
  const [value, setValue] = useState('one');
  return (
    <>
      <Radio.Group
        name="difficultyRadioGroup"
        value={GameDifficulty[currentDifficulty]}
        onChange={(nextValue: string) => {
          setCurrentDifficulty(GameDifficulty[nextValue]);
          difficultyCallback(GameDifficulty[nextValue]);
        }}
        colorScheme="orange"
        width="full"
      >
        {listData.map((difficulty, index) => (
          <Box
            key={index}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            width="full"
            padding={1}
          >
            <Text
              flex={1}
              onPress={() => {
                setCurrentDifficulty(difficulty);
                difficultyCallback(difficulty);
              }}
            >
              {GameDifficulty[difficulty]}
            </Text>
            <Spacer />
            <Radio value={GameDifficulty[difficulty]}></Radio>
          </Box>
        ))}
      </Radio.Group>
    </>
  );
};
