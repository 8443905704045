import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Text } from 'native-base';
import { FC, useEffect, useRef, useState } from 'react';
import { RootStackParamList } from '../components/navigation';
import { fireAuth } from '../backend';

type AccountPageProps = NativeStackScreenProps<RootStackParamList, 'account'>;
export const AccountPage: FC<AccountPageProps> = ({
  route,
  navigation,
}: AccountPageProps) => {
  const [signOutSuccessful, setSignOutSuccessful] = useState<null | boolean>(
    null
  );
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      fireAuth
        .signOut()
        .then(() => setSignOutSuccessful(true))
        .catch(() => setSignOutSuccessful(false));
    }
  }, []);

  if (signOutSuccessful === null) {
    return <Text>Signing out...</Text>;
  }

  return (
    <Text>
      {signOutSuccessful ? 'Signout successful' : 'Signout unsuccessful'}
    </Text>
  );
};
