import React, { FC, useContext } from 'react';
import {
  HStack,
  IconButton,
  Icon,
  Text,
  Box,
  StatusBar,
  useColorMode,
  Button,
} from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';
import { NativeStackHeaderProps } from '@react-navigation/native-stack';
import { DrawerContext } from '../App';
import { useSelector } from 'react-redux';
import { RootState } from '../state/store';

export const NavBar: FC<NativeStackHeaderProps> = ({
  route,
  back,
  navigation,
}) => {
  const drawerContext = useContext(DrawerContext);
  const currentDebugMode = useSelector(
    (state: RootState) => state?.appConfiguration?.debugMode
  );
  const { toggleColorMode, colorMode } = useColorMode();
  return (
    <>
      <StatusBar
        barStyle={colorMode === 'light' ? 'light-content' : 'dark-content'}
      />
      <Box
        safeAreaTop
        _light={{ bg: 'primary.900' }}
        _dark={{ bg: 'secondary.500' }}
      />
      <HStack
        _light={{ bg: 'primary.900' }}
        _dark={{ bg: 'secondary.500' }}
        px={2}
        py={4}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box flex={1} alignItems="flex-start">
          {route.name === 'home' ? (
            <IconButton
              icon={
                <Icon size="md" as={MaterialIcons} name="menu" color="white" />
              }
              onPress={() => drawerContext?.open()}
            />
          ) : (
            <IconButton
              icon={
                <Icon
                  size="md"
                  as={MaterialIcons}
                  name="arrow-back"
                  color="white"
                />
              }
              onPress={() => {
                if (['startnew', 'composegame', 'game'].includes(back?.title)) {
                  navigation.navigate('home');
                  return;
                }
                if (navigation.canGoBack()) {
                  navigation.goBack();
                } else {
                  navigation.navigate('home');
                }
              }}
            />
          )}
        </Box>
        <Box flex={1} alignItems="center">
          <Text
            fontWeight="bold"
            fontSize="lg"
            color="white"
            textAlign="center"
            flex={1}
            onPress={() => navigation.navigate('home')}
          >
            {currentDebugMode === true ? 'DEBUG MODE' : 'Find It!'}
          </Text>
        </Box>
        <Box flex={1} alignItems="flex-end">
          <Button.Group>
            <IconButton
              onPress={() => toggleColorMode()}
              icon={
                <Icon
                  as={MaterialIcons}
                  name={colorMode === 'light' ? 'nightlight-round' : 'wb-sunny'}
                  size="md"
                  color="white"
                />
              }
            />
          </Button.Group>
        </Box>
      </HStack>
    </>
  );
};
