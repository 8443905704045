import Constants from 'expo-constants';
const schemaVersion = Constants?.expoConfig?.extra?.schemaVersion;
import { Provider } from 'react-redux';
import { RootSiblingParent } from 'react-native-root-siblings';
import { Center, extendTheme, Heading, NativeBaseProvider } from 'native-base';
import React, { FC, useEffect, useRef, useState } from 'react';
import { colorModeManager, findItTheme } from './assets/theme';
import { store } from './state/store';
import { Unsubscribe } from 'firebase/auth';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import { initializeAppVersionSnapshot } from './firebase-utilities';
import { Loader } from './components/loader';
import { AppContent } from './components/app-content';
import Drawer from 'react-native-drawer';

export enum AppVersionCheckEnum {
  pendingServerCheck,
  supportedVersion,
  unsupportedVersion,
  serverErrorWhenChecking,
}

const findItLightTheme = extendTheme({ colors: findItTheme });

const App: FC = () => {
  const [findItAppVersionCheck, setFindItAppVersionCheck] =
    useState<AppVersionCheckEnum>(AppVersionCheckEnum.pendingServerCheck);
  const serverHasBeenCheckedForLatestVersionOfFindIt = useRef<boolean>(false);
  const appVersionSnapshotUnsubscribeRef = useRef<Unsubscribe | null>(null);
  useEffect(() => {
    if (serverHasBeenCheckedForLatestVersionOfFindIt.current) {
      return;
    }
    initializeAppVersionSnapshot((snapshot: QuerySnapshot<DocumentData>) => {
      if (snapshot.docs.length === 0) {
        setFindItAppVersionCheck(AppVersionCheckEnum.unsupportedVersion);
      }
      const acceptableVersions = snapshot.docs.map(
        (doc) => doc.data().version as string
      );
      setFindItAppVersionCheck(
        acceptableVersions.includes(schemaVersion)
          ? AppVersionCheckEnum.supportedVersion
          : AppVersionCheckEnum.unsupportedVersion
      );
    })
      .then(
        (unsubscribe: Unsubscribe) =>
          (appVersionSnapshotUnsubscribeRef.current = unsubscribe)
      )
      .catch((error: Error) => {
        console.error(error);
        setFindItAppVersionCheck(AppVersionCheckEnum.serverErrorWhenChecking);
      });
    serverHasBeenCheckedForLatestVersionOfFindIt.current = true;
    return () => appVersionSnapshotUnsubscribeRef?.current();
  }, []);
  return (
    <Provider store={store}>
      <RootSiblingParent>
        <NativeBaseProvider
          theme={findItLightTheme}
          colorModeManager={colorModeManager}
        >
          <InnerRender findItAppVersionCheck={findItAppVersionCheck} />
        </NativeBaseProvider>
      </RootSiblingParent>
    </Provider>
  );
};

type InnerRenderProps = {
  findItAppVersionCheck: AppVersionCheckEnum;
};
const InnerRender: FC<InnerRenderProps> = ({ findItAppVersionCheck }) => {
  switch (findItAppVersionCheck) {
    case AppVersionCheckEnum.pendingServerCheck:
      return (
        <Center height="100%">
          <Loader />
        </Center>
      );
    case AppVersionCheckEnum.supportedVersion:
      return <AppContent />;
    case AppVersionCheckEnum.unsupportedVersion:
      return (
        <Center height="100%">
          <Heading color="primary.900" textAlign="center">
            Please Download The Latest Version of Find It!
          </Heading>
        </Center>
      );
    case AppVersionCheckEnum.serverErrorWhenChecking:
      return (
        <Center height="100%">
          <Heading color="primary.900" textAlign="center">
            There was an error checking the server for the latest version of
            Find It!
          </Heading>
        </Center>
      );
    default:
      return <></>;
  }
};
export const DrawerContext = React.createContext<Drawer | null>(null);
export default App;
