import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GameState } from './game-types';
import { ofType, StateObservable } from 'redux-observable';
import { catchError, map, mergeMap, Observable, of, from } from 'rxjs';
import { FindItError, RootState } from '../store';
import {
  joinGameInFirebase,
  startNewGameInFirebase,
} from '../../game-utilities';
import {
  FirebaseGameBoxDocument,
  GameBox,
  GameBoxCategory,
  GameDifficulty,
  IGame,
} from '../../models/game.types';
import { loadCheckboxMetaDataFromFirebase } from '../../firebase-utilities';

const initialGameState: GameState = {
  game: null,
  gameId: null,
  checkboxes: [],
  error: null,
  loading: false,
  checkboxMetaData: [],
  imageUrls: {},
  inviteOthersModalVisible: false,
  endGameModalVisible: false,
  imagePreviewModalVisible: false,
  imagePreviewCheckbox: null,
};

export const gameSlice = createSlice({
  name: 'game',
  initialState: initialGameState,
  reducers: {
    startNewGame: (
      state,
      action: PayloadAction<{
        categories: GameBoxCategory[];
        difficulty: GameDifficulty;
      }>
    ) => {
      console.log(
        'action startNewGame called with state, and action',
        state,
        action
      );
      state.loading = true;
      return state;
    },
    startNewGameSuccess: (
      state,
      action: PayloadAction<{ game: IGame; checkboxes: GameBox[] }>
    ) => {
      console.log(
        'action startNewGameSuccess called with state, and action',
        state,
        action
      );
      const { game, checkboxes } = action.payload;
      state.game = game;
      state.checkboxes = checkboxes;
      state.error = null;
      state.loading = false;
      return state;
    },
    startNewGameFailure: (
      state,
      action: PayloadAction<{ error: FindItError }>
    ) => {
      console.log(
        'action startNewGameFailure called with state, and action',
        state,
        action
      );
      const { error } = action.payload;
      state.error = { message: error.message, stack: error.stack };
      state.loading = false;
      return state;
    },
    joinGame: (state, action: PayloadAction<{ gameId: string }>) => {
      console.log(
        'action joinGame called with state, and action',
        state,
        action
      );
      state.loading = true;
      return state;
    },
    joinGameSuccess: (state, action: PayloadAction<{ game: IGame }>) => {
      console.log(
        'action joinGameSuccess called with state, and action',
        state,
        action
      );
      const { game } = action.payload;
      state.game = game;
      state.error = null;
      state.loading = false;
      return state;
    },
    joinGameFailure: (state, action: PayloadAction<{ error: FindItError }>) => {
      console.log(
        'action joinGameFailure called with state, and action',
        state,
        action
      );
      const { error } = action.payload;
      state.error = { message: error.message, stack: error.stack };
      state.loading = false;
      return state;
    },
    continueGame: (state, action: PayloadAction<{}>) => {
      console.log(
        'action continueGame called with state, and action',
        state,
        action
      );
      state.loading = true;
      return state;
    },
    continueGameSuccess: (state, action: PayloadAction<{ game: IGame }>) => {
      console.log(
        'action continueGameSuccess called with state, and action',
        state,
        action
      );
      const { game } = action.payload;
      state.game = game;
      state.error = null;
      state.loading = false;
      return state;
    },
    continueGameFailure: (
      state,
      action: PayloadAction<{ error: FindItError }>
    ) => {
      console.log(
        'action continueGameFailure called with state, and action',
        state,
        action
      );
      const { error } = action.payload;
      state.error = { message: error.message, stack: error.stack };
      state.loading = false;
      return state;
    },
    loadCheckboxMetaData: (state, action: PayloadAction<{}>) => {
      console.log(
        'action loadCheckboxMetaData called with state, and action',
        state,
        action
      );
      state.loading = true;
      return state;
    },
    loadCheckboxMetaDataSuccess: (
      state,
      action: PayloadAction<{ metaData: GameBox[] }>
    ) => {
      console.log(
        'action loadCheckboxMetaDataSuccess called with state, and action',
        state,
        action
      );
      const { metaData } = action.payload;
      state.checkboxMetaData = metaData;
      state.error = null;
      state.loading = false;
      return state;
    },
    loadCheckboxMetaDataFailure: (
      state,
      action: PayloadAction<{ error: FindItError }>
    ) => {
      console.log(
        'action loadCheckboxMetaDataFailure called with state, and action',
        state,
        action
      );
      const { error } = action.payload;
      state.error = { message: error.message, stack: error.stack };
      state.loading = false;
      return state;
    },
    setGame: (
      state,
      action: PayloadAction<{ gameId: string; game: IGame }>
    ) => {
      console.log(
        'action setGame called with state, and action',
        state,
        action
      );
      const { game, gameId } = action.payload;
      state.gameId = gameId;
      state.game = game;
      return state;
    },
    setCheckboxes: (
      state,
      action: PayloadAction<{ checkboxes: GameBox[] }>
    ) => {
      console.log(
        'action setCheckboxes called with state, and action',
        state,
        action
      );
      const { checkboxes } = action.payload;
      state.checkboxes = checkboxes;
      return state;
    },
    updateImageUrl: (
      state,
      action: PayloadAction<{ checkboxId: string; imageUrl: string }>
    ) => {
      console.log(
        'action updateImageUrl called with state, and action',
        state,
        action
      );
      const { checkboxId, imageUrl } = action.payload;
      state.imageUrls[checkboxId] = imageUrl;
      return state;
    },
    updateCheckbox: (
      state,
      action: PayloadAction<{
        checkboxId: string;
        gameId: string;
        checked: boolean;
      }>
    ) => {
      console.log(
        'action updateCheckbox called with state, and action',
        state,
        action
      );
      const { checkboxId, checked } = action.payload;
      const checkbox = state.checkboxes.find(
        (checkboxItem) => checkboxItem.id === checkboxId
      );
      if (checkbox == null || checkbox.checked === checked) {
        return state;
      }
      checkbox.checked = checked;
      return state;
    },
    setInviteOthersModalVisibility: (
      state,
      action: PayloadAction<{ visible: boolean }>
    ) => {
      console.log(
        'action setInviteOthersModalVisibility called with state, and action',
        state,
        action
      );
      const { visible } = action.payload;
      state.inviteOthersModalVisible = visible;
      return state;
    },
    setEndGameModalVisibility: (
      state,
      action: PayloadAction<{ visible: boolean }>
    ) => {
      console.log(
        'action setEndGameModalVisibility called with state, and action',
        state,
        action
      );
      const { visible } = action.payload;
      state.endGameModalVisible = visible;
      return state;
    },
    setImagePreviewModalVisibility: (
      state,
      action: PayloadAction<{ visible: boolean }>
    ) => {
      console.log(
        'action setImagePreviewModalVisibility called with state, and action',
        state,
        action
      );
      const { visible } = action.payload;
      state.imagePreviewModalVisible = visible;
      return state;
    },
    setImagePreviewModalCheckbox: (
      state,
      action: PayloadAction<{ checkbox: FirebaseGameBoxDocument | GameBox }>
    ) => {
      console.log(
        'action setImagePreviewModalImageUrl called with state, and action',
        state,
        action
      );
      const { checkbox } = action.payload;
      state.imagePreviewCheckbox = checkbox;
      return state;
    },
    noOp: (state, action: PayloadAction<{}>) => {
      console.log('action noOp called with state, and action', state, action);
      return state;
    },
  },
});

export const {
  startNewGame,
  startNewGameSuccess,
  startNewGameFailure,
  joinGame,
  joinGameSuccess,
  joinGameFailure,
  continueGame,
  continueGameSuccess,
  continueGameFailure,
  loadCheckboxMetaData,
  loadCheckboxMetaDataSuccess,
  loadCheckboxMetaDataFailure,
  setGame,
  setCheckboxes,
  updateImageUrl,
  updateCheckbox,
  setInviteOthersModalVisibility,
  setEndGameModalVisibility,
  setImagePreviewModalVisibility,
  setImagePreviewModalCheckbox,
  noOp,
} = gameSlice.actions;

export const gameReducer = gameSlice.reducer;

const startNewGameEpic$ = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(startNewGame.type),
    mergeMap(
      (
        action: PayloadAction<{
          categories: GameBoxCategory[];
          difficulty: GameDifficulty;
        }>
      ) =>
        from(
          startNewGameInFirebase(
            action.payload.categories,
            action.payload.difficulty
          )
        ).pipe(
          catchError((error) => {
            console.log('startNewGameEpic error', error);
            return of({
              message: error.message,
              stack: error.stack,
              isError: true,
            } as FindItError);
          })
        )
    ),
    map((response: { game: IGame; checkboxes: GameBox[] } | FindItError) => {
      if (
        (response as FindItError)?.isError != null &&
        (response as FindItError)?.isError === true
      ) {
        return startNewGameFailure({ error: response as FindItError });
      }
      console.log('startNewGameEpic response', response);
      return startNewGameSuccess(
        response as { game: IGame; checkboxes: GameBox[] }
      );
    })
  );

const joinGameEpic$ = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(joinGame.type),
    mergeMap((action: PayloadAction<{ gameId: string }>) =>
      from(
        joinGameInFirebase(
          action.payload.gameId,
          state$.value.appConfiguration.currentUser.uid
        )
      ).pipe(
        catchError((error) => {
          console.log('joinGameEpic error', error);
          return of({
            message: error.message,
            stack: error.stack,
            isError: true,
          });
        })
      )
    ),
    map((response: { game: IGame; checkboxes: GameBox[] } | FindItError) => {
      if (
        (response as FindItError)?.isError != null &&
        (response as FindItError)?.isError === true
      ) {
        return joinGameFailure({ error: response as FindItError });
      }
      console.log('joinGameEpic response', response);
      return joinGameSuccess(
        response as { game: IGame; checkboxes: GameBox[] }
      );
    })
  );

const loadCheckboxMetaDataEpic$ = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(loadCheckboxMetaData.type),
    mergeMap(() =>
      from(loadCheckboxMetaDataFromFirebase()).pipe(
        catchError((error) => {
          console.log('loadCheckboxMetaDataEpic error', error);
          return of({
            message: error.message,
            stack: error.stack,
            isError: true,
          } as FindItError);
        })
      )
    ),
    map((response: GameBox[] | FindItError) => {
      if (
        (response as FindItError)?.isError != null &&
        (response as FindItError)?.isError === true
      ) {
        return loadCheckboxMetaDataFailure({
          error: response as FindItError,
        });
      }
      return loadCheckboxMetaDataSuccess({ metaData: response as GameBox[] });
    })
  );

export const gameEpics = [
  startNewGameEpic$,
  joinGameEpic$,
  loadCheckboxMetaDataEpic$,
  // updateCheckboxEpic$,
];
