import { MaterialIcons } from '@expo/vector-icons';
import { Modal, Button, Icon, Heading, VStack, Text } from 'native-base';
import { FC, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { RootState } from '../../state/store';
import { useSelector } from 'react-redux';
import * as MailComposer from 'expo-mail-composer';
import * as SMS from 'expo-sms';

export interface InviteOthersModalProps {
  getResponse: (response: 'message' | 'email' | 'copy' | 'cancel') => void;
}

const InviteOthersModal: FC<InviteOthersModalProps> = ({ getResponse }) => {
  const modalVisible = useSelector(
    (state: RootState) => state.game.inviteOthersModalVisible ?? false
  );
  const [mailAvailable, setMailAvailable] = useState(false);
  const [smsAvailable, setSMSAvailable] = useState(false);
  useEffect(() => {
    if (modalVisible) {
      MailComposer.isAvailableAsync()
        .then((result) => setMailAvailable(result))
        .catch((error) => console.log(error));
      SMS.isAvailableAsync()
        .then((result) => setSMSAvailable(result))
        .catch((error) => console.log(error));
    }
  }, [modalVisible]);
  return (
    <Modal
      isOpen={modalVisible}
      onClose={getResponse}
      size="full"
      maxWidth={380}
      marginLeft="auto"
      marginRight="auto"
      alignContent="center"
    >
      <Modal.Content height={smsAvailable ? 460 : 410}>
        <Modal.Body>
          <VStack space={1} alignItems="center" height="100%">
            <Heading
              size="lg"
              textAlign="center"
              _dark={{ color: 'primary.900' }}
              _light={{ color: 'secondary.500' }}
            >
              Invite others to play
            </Heading>
            <Icon
              size="200"
              as={MaterialIcons}
              name="person-add"
              _dark={{ color: 'primary.900' }}
              _light={{ color: 'secondary.500' }}
            />
            <Button.Group
              direction="column"
              width="full"
              size="full"
              maxWidth={500}
              _text={{ fontSize: 15 }}
              alignSelf={'flex-end'}
            >
              {smsAvailable ? (
                <Button
                  onPress={() => getResponse('message')}
                  _dark={{ bg: 'primary.900' }}
                  _light={{ bg: 'secondary.500' }}
                  height={41}
                >
                  Send As A Message
                </Button>
              ) : null}
              {mailAvailable ? (
                <Button
                  bg="gray.700"
                  onPress={() => getResponse('email')}
                  height={41}
                >
                  Send as an Email
                </Button>
              ) : null}
              <Button
                bg="gray.700"
                onPress={() => getResponse('copy')}
                height={41}
              >
                Copy Invite Code
              </Button>
              <Button
                bg="gray.700"
                onPress={() => getResponse('cancel')}
                height={41}
              >
                Cancel
              </Button>
            </Button.Group>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
export default InviteOthersModal;
