import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
  createNavigationContainerRef,
} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useColorModeValue, Text } from 'native-base';
import { NavBar } from './nav-bar';
import { AccountPage } from '../pages/account-page';
import { GamePage } from '../pages/game-page';
import { HomePage } from '../pages/home-page';
import { FC } from 'react';
import { StartNewPage } from '../pages/start-new';
import { JoinPage } from '../pages/join-page';
import { ComposeGamePage } from '../pages/compose-game';
import { LoginRegisterPage } from '../pages/login-register-page';
import { HistoryPage } from '../pages/history-page';

import * as Linking from 'expo-linking';
import Constants from 'expo-constants';
import { DeleteMyDataPage } from '../pages/delete-my-data-page';

const prefix = Linking.createURL('/');
const matchingWebUrl = Constants?.expoConfig?.extra?.matchingWebUrl;

const Stack = createNativeStackNavigator();
export const navigationRef = createNavigationContainerRef<RootStackParamList>();

export type RootStackParamList = {
  home: undefined;
  game: { gameId: string };
  account: undefined;
  startnew: undefined;
  continue: undefined;
  join: { gameId: string } | undefined;
  composegame: undefined;
  loginregister: undefined;
  history: undefined;
  deletemydata: undefined;
};

export const Navigation: FC<{}> = () => {
  const navigationTheme = useColorModeValue(DefaultTheme, DarkTheme);
  const linking = {
    prefixes: [prefix, matchingWebUrl],
  };
  return (
    <NavigationContainer
      theme={navigationTheme}
      ref={navigationRef}
      linking={linking}
    >
      <Stack.Navigator
        initialRouteName="home"
        screenOptions={{
          header: (props) => <NavBar {...props} />,
        }}
      >
        <Stack.Screen name="home" component={HomePage} />
        <Stack.Screen name="game" component={GamePage} />
        <Stack.Screen name="account" component={AccountPage} />
        <Stack.Screen name="startnew" component={StartNewPage} />
        <Stack.Screen name="join" component={JoinPage} />
        <Stack.Screen name="composegame" component={ComposeGamePage} />
        <Stack.Screen name="loginregister" component={LoginRegisterPage} />
        <Stack.Screen name="history" component={HistoryPage} />
        <Stack.Screen name="deletemydata" component={DeleteMyDataPage} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};
