import Constants from 'expo-constants';
const schemaVersion = Constants?.expoConfig?.extra?.schemaVersion;
import {
  Auth,
  AuthCredential,
  AuthProvider,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  linkWithCredential,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  User,
  UserCredential,
} from 'firebase/auth';
import {
  collection,
  doc,
  getDoc,
  query,
  runTransaction,
  DocumentData,
  where,
  setDoc,
  Timestamp,
  updateDoc,
  onSnapshot,
  QuerySnapshot,
  deleteDoc,
  getDocs,
  arrayRemove,
} from 'firebase/firestore';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { httpsCallable, HttpsCallable } from 'firebase/functions';
import Toast from 'react-native-root-toast';
import { fireAuth, fireFunctions, fireStore, fireStorage } from './backend';
import { Platform } from 'react-native';
import * as SecureStore from 'expo-secure-store';
import { Game } from './models/game.model';
import {
  AllGameBoxCategories,
  FindItUser,
  GameDifficulty,
  UserStatsData,
} from './models/game.types';

const placeholderImage = require('./assets/placeholder.svg');

export const deepClone = <T>(item: T): T => JSON.parse(JSON.stringify(item));

export const initializeAppVersionSnapshot = async (
  callback: (snapshot: QuerySnapshot<DocumentData>) => void
) => {
  const appVersions = collection(fireStore, 'appVersions');
  const appVersionQuery = query(appVersions, where('supported', '==', true));
  return onSnapshot(appVersionQuery, callback);
};

export const anonymousSignIn = async (
  debugMode: boolean = false
): Promise<any> => {
  try {
    const credential = await signInAnonymously(fireAuth);
    await updateUserData(credential.user, null, debugMode);
  } catch (e) {
    if (debugMode) {
      const toast = Toast.show(
        `Error in anonymousSignIn: ${JSON.stringify(e)}`,
        {
          duration: 5000,
        }
      );
      setTimeout(() => {
        Toast.hide(toast);
      }, 5000);
    }
    console.log('error logging in', e);
  }
};

export const linkFirebaseToGoogleNativeSignIn = async (
  debugMode: boolean = false,
  idToken: string,
  accessToken: string
) => {
  try {
    const googleCredential = GoogleAuthProvider.credential(
      idToken,
      accessToken
    );
    const userCredential = await signInWithCredential(
      fireAuth,
      googleCredential
    );
    const credential = GoogleAuthProvider.credentialFromResult(userCredential);
    if (fireAuth?.currentUser?.isAnonymous ?? true) {
      await upgradeAnonymousAccount(credential, debugMode);
    }
    await updateUserData(
      fireAuth.currentUser,
      fireAuth.currentUser.displayName,
      debugMode
    );
  } catch (error) {
    if (debugMode) {
      const toast = Toast.show(
        `Error in signInWithGoogle: ${error?.message ?? error?.code ?? error}`,
        {
          duration: 5000,
        }
      );
      setTimeout(() => {
        Toast.hide(toast);
      }, 5000);
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }
    console.log('error in signInWithGoogle', error);
  }
};

export const signInWithGoogle = async (
  debugMode: boolean = false
): Promise<void> => {
  try {
    const provider = new GoogleAuthProvider();
    const userCredential = await signInWithProvider(provider);
    const credential = GoogleAuthProvider.credentialFromResult(userCredential);
    if (fireAuth?.currentUser?.isAnonymous ?? true) {
      await upgradeAnonymousAccount(credential, debugMode);
    }
    await updateUserData(
      fireAuth.currentUser,
      fireAuth.currentUser.displayName,
      debugMode
    );
  } catch (e) {
    if (debugMode) {
      const toast = Toast.show(
        `Error in signInWithGoogle: ${JSON.stringify(e)}`,
        {
          duration: 5000,
        }
      );
      setTimeout(() => {
        Toast.hide(toast);
      }, 5000);
    }
    console.log('error in signInWithGoogle', e);
    throw e;
  }
};

export const signInWithFacebook = async (
  debugMode: boolean = false
): Promise<void> => {
  try {
    const provider = new FacebookAuthProvider();
    const userCredential = await signInWithProvider(provider, debugMode);
    const credential =
      FacebookAuthProvider.credentialFromResult(userCredential);
    if (fireAuth?.currentUser?.isAnonymous ?? true) {
      await upgradeAnonymousAccount(credential, debugMode);
    }
    updateUserData(fireAuth.currentUser, null, debugMode);
  } catch (e) {
    if (debugMode) {
      const toast = Toast.show(
        `Error in signInWithFacebook: ${JSON.stringify(e)}`,
        {
          duration: 5000,
        }
      );
      setTimeout(() => {
        Toast.hide(toast);
      }, 5000);
    }
    console.log('error in signInWithFacebook', e);
    throw e;
  }
};

export const loadCheckboxMetaDataFromFirebase = async () => {
  return await Game.getCheckBoxesFromFirebase(
    fireStore,
    true,
    [...AllGameBoxCategories],
    GameDifficulty.Hard
  );
};

export const signUserInWithEmailAndPassword = async (
  email: string,
  password: string,
  displayName: string | null = null,
  createAccountIfNotExists: boolean = false,
  debugMode: boolean = false
): Promise<void> => {
  try {
    try {
      await signInWithEmailAndPassword(fireAuth, email, password);
    } catch (signInException) {
      if (
        signInException.code === 'auth/user-not-found' &&
        createAccountIfNotExists
      ) {
        await createUserWithEmailAndPassword(fireAuth, email, password);
      } else {
        throw signInException;
      }
    }
    if (!createAccountIfNotExists) {
      return;
    }
    const credential = EmailAuthProvider.credential(email, password);
    if (fireAuth?.currentUser?.isAnonymous ?? true) {
      await upgradeAnonymousAccount(credential, debugMode);
    }
    await updateUserData(
      fireAuth.currentUser,
      displayName ?? fireAuth.currentUser.displayName,
      debugMode
    );
  } catch (e) {
    if (debugMode) {
      const toast = Toast.show(
        `Error in signInWithEmailAndPassword: ${JSON.stringify(e)}`,
        {
          duration: 5000,
        }
      );
      setTimeout(() => {
        Toast.hide(toast);
      }, 5000);
    }
    console.log('error in signInWithEmailAndPassword', e);
    throw e;
  }
};

export const upgradeAnonymousAccount = async (
  credential: AuthCredential,
  debugMode: boolean = false
): Promise<void> => {
  try {
    if (!fireAuth.currentUser) {
      throw new Error('No current user');
    }
    await linkWithCredential(fireAuth.currentUser, credential);
  } catch (e) {
    if (debugMode) {
      const toast = Toast.show(
        `Error in upgradeAnonymousAccount: ${JSON.stringify(e)}`,
        {
          duration: 5000,
        }
      );
      setTimeout(() => {
        Toast.hide(toast);
      }, 5000);
    }
    console.log('Error upgrading anonymous account', e);
    throw e;
  }
};

export const presentToast = (
  message: string,
  debugMode: boolean = false,
  toastColor: string = null,
  duration: number = 10000
): void => {
  try {
    const toastOptions = {
      duration,
      backgroundColor: null,
    };

    if (toastColor != null) {
      toastOptions.backgroundColor = toastColor;
    }

    const toast = Toast.show(message, toastOptions);
    setTimeout(() => {
      Toast.hide(toast);
    }, duration);
  } catch (e) {
    if (debugMode) {
      const toast = Toast.show(`Error in presentToast: ${JSON.stringify(e)}`, {
        duration,
      });
      setTimeout(() => {
        Toast.hide(toast);
      }, duration);
    }
    console.log('error in presentToast', e);
    throw e;
  }
};

export const resetPassword = async (
  email: string,
  debugMode: boolean = false
): Promise<void> => {
  try {
    await sendPasswordResetEmail(fireAuth, email);
  } catch (e) {
    if (debugMode) {
      const toast = Toast.show(`Error in resetPassword: ${JSON.stringify(e)}`, {
        duration: 5000,
      });
      setTimeout(() => {
        Toast.hide(toast);
      }, 5000);
    }
    console.log('error in resetPassword', e);
    throw e;
  }
};

export const signOut = async (debugMode: boolean = false) => {
  try {
    await fireAuth.signOut();
    if (Platform.OS !== 'web') {
      // await GoogleSignin.signOut();
    }
  } catch (e) {
    if (debugMode) {
      const toast = Toast.show(`Error in signOut: ${JSON.stringify(e)}`, {
        duration: 5000,
      });
      setTimeout(() => {
        Toast.hide(toast);
      }, 5000);
    }
    console.log('error in signOut', e);
  }
};

export const updateUserDisplayName = async (
  userId: string,
  displayName: string,
  debugMode: boolean = false
) => {
  try {
    const userRef = doc(fireStore, `users${schemaVersion}`, userId);
    const userDataSnapshot = await getDoc(userRef);
    const userData = userDataSnapshot.data();
    if (!userData.exists()) {
      throw new Error(`User with provided id ${userId} does not exist`);
    }
    await updateDoc(userRef, {
      displayName,
    });
  } catch (e) {
    if (debugMode) {
      const toast = Toast.show(
        `Error in updateUserDisplayName: ${JSON.stringify(e)}`,
        {
          duration: 5000,
        }
      );
      setTimeout(() => {
        Toast.hide(toast);
      }, 5000);
    }
    console.log('error updating user data', e);
    throw e;
  }
};

export const resetUserStats = async (
  userId: string,
  debugMode: boolean = false
) => {
  try {
    const userRef = doc(fireStore, `users${schemaVersion}`, userId);
    const userDataSnapshot = await getDoc(userRef);
    if (!userDataSnapshot.exists()) {
      throw new Error(`User ${userId} does not exist`);
    }

    await updateDoc(userRef, {
      stats: null,
      effectiveStatDate: Timestamp.now(),
    });
  } catch (e) {
    if (debugMode) {
      const toast = Toast.show(
        `Error in resetUserStats: ${JSON.stringify(e)}`,
        {
          duration: 5000,
        }
      );
      setTimeout(() => {
        Toast.hide(toast);
      }, 5000);
    }
    console.error('resetUserStats failed with error', e);
  }
};

export const signInWithProvider = async (
  provider: AuthProvider,
  debugMode: boolean = false
): Promise<UserCredential> => {
  try {
    const credential = await signInWithPopup(fireAuth, provider);
    return credential;
  } catch (e: any) {
    if (debugMode) {
      const message = `Error in signInWithProvider, error: ${JSON.stringify({
        message: e?.message,
        stack: e?.stack,
        code: e?.code,
        customData: e?.customData,
        name: e.name,
      })}, provider: ${JSON.stringify(provider)}`;
      const toast = Toast.show(message, {
        duration: 5000,
      });
      setTimeout(() => {
        Toast.hide(toast);
      }, 5000);
      presentToast('Copied error to clipboard!', debugMode);
    }
  }
};

export const getImageFromFireStorage = async (
  imageId: string,
  debugMode: boolean = false
): Promise<string> => {
  try {
    const imageRef = storageRef(fireStorage, `${imageId}`);
    const imageSnapshot = await getDownloadURL(imageRef);
    return imageSnapshot;
  } catch (e) {
    if (debugMode) {
      const toast = Toast.show(
        `Error in getImageFromFireStorage: ${JSON.stringify(e)}`,
        {
          duration: 5000,
        }
      );
      setTimeout(() => {
        Toast.hide(toast);
      }, 5000);
    }
    console.log('error in getImageFromFireStorage', e);
    return placeholderImage;
  }
};

export const updateUserData = async (
  userToUpdate: User,
  displayName: string | null = null,
  debugMode: boolean = false
): Promise<void> => {
  try {
    const userRef = doc(fireStore, `users${schemaVersion}`, userToUpdate.uid);
    const userDataSnapshot = await getDoc(userRef);
    const userData = userDataSnapshot.data() as FindItUser;
    const randomName = `User${
      Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000
    }`;
    if (!userToUpdate?.displayName && !userData?.displayName) {
      presentToast(
        `Looks like you're new here. I'm going to call you ${randomName}. Nice to Meet you ${randomName}!`,
        debugMode,
        null,
        5000
      );
    }
    if (!userData) {
      await setDoc(userRef, {
        userId: userToUpdate.uid,
        currentGameId: null,
      });
    }
    if (!userData?.displayName) {
      await updateDoc(userRef, {
        displayName: userToUpdate?.displayName ?? displayName ?? randomName,
      });
    }
  } catch (e) {
    if (debugMode) {
      const toast = Toast.show(
        `Error in updateUserData: ${JSON.stringify(e)}`,
        {
          duration: 5000,
        }
      );
      setTimeout(() => {
        Toast.hide(toast);
      }, 5000);
    }
    console.log('error updating user data', e);
    throw e;
  }
};

export const deleteMyData = async () => {
  if (!fireAuth.currentUser) {
    return;
  }
  const userId = fireAuth.currentUser.uid;
  await runTransaction(fireStore, async (transaction) => {
    const userRef = doc(fireStore, `users${schemaVersion}`, userId);
    transaction.delete(userRef);

    const gamesCollectionRef = collection(fireStore, `games${schemaVersion}`);
    const gamesWithUserInItQuery = query(
      gamesCollectionRef,
      where('playerIds', 'array-contains', userId)
    );
    const games = await getDocs(gamesWithUserInItQuery);
    for (const gameDoc of games.docs) {
      const previousUserPlayerEntry = gameDoc
        .data()
        .players.find((player) => player.userId === userId);
      transaction.update(gameDoc.ref, {
        players: arrayRemove({ ...previousUserPlayerEntry }),
        playerIds: arrayRemove(userId),
      });
    }
  });

  await fireAuth.currentUser.delete();
  await fireAuth.signOut();
};

export const getUserStatusHttpCallable = (): HttpsCallable<
  {
    userId: string;
  },
  UserStatsData
> => {
  const getUserStats = httpsCallable<{ userId: string }, UserStatsData>(
    fireFunctions,
    'getUserStats'
  );
  return getUserStats;
};

export const setItemInStorageAsync = async (
  key: string,
  value: string
): Promise<void> => {
  console.log('getItemFromStorageAsync', key);
  Platform.OS === 'web'
    ? localStorage.setItem(key, value)
    : await SecureStore.setItemAsync(key, value);
};

export const getItemFromStorageAsync = async (key: string): Promise<string> => {
  console.log('getItemFromStorageAsync', key);
  return Platform.OS === 'web'
    ? localStorage.getItem(key)
    : await SecureStore.getItemAsync(key);
};
