import { GameBox, GameDifficulty } from './game.types';

export const checkboxes: GameBox[] = [
  {
    displayName: 'BISON / BUFFALO',
    id: 'BISON_BUFFALO',
    category: 'ANIMALS',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/ANIMALS/BISON_BUFFALO.png',
  },
  {
    displayName: 'CALF',
    id: 'CALF',
    category: 'ANIMALS',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/ANIMALS/CALF.png',
  },
  {
    displayName: 'CHICKEN',
    id: 'CHICKEN',
    category: 'ANIMALS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ANIMALS/CHICKEN.png',
  },
  {
    displayName: 'COLT',
    id: 'COLT',
    category: 'ANIMALS',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/ANIMALS/COLT.png',
  },
  {
    displayName: 'COW',
    id: 'COW',
    category: 'ANIMALS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/ANIMALS/COW.png',
  },
  {
    displayName: 'DEER',
    id: 'DEER',
    category: 'ANIMALS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/ANIMALS/DEER.png',
  },
  {
    displayName: 'DOG',
    id: 'DOG',
    category: 'ANIMALS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ANIMALS/DOG.png',
  },
  {
    displayName: 'DONKEY',
    id: 'DONKEY',
    category: 'ANIMALS',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/ANIMALS/DONKEY.png',
  },
  {
    displayName: 'FALCON',
    id: 'FALCON',
    category: 'ANIMALS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ANIMALS/FALCON.png',
  },
  {
    displayName: 'HORSE',
    id: 'HORSE',
    category: 'ANIMALS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/ANIMALS/HORSE.png',
  },
  {
    displayName: 'LLAMA',
    id: 'LLAMA',
    category: 'ANIMALS',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/ANIMALS/LLAMA.png',
  },
  {
    displayName: 'PIG',
    id: 'PIG',
    category: 'ANIMALS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ANIMALS/PIG.png',
  },
  {
    displayName: 'SHEEP',
    id: 'SHEEP',
    category: 'ANIMALS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/ANIMALS/SHEEP.png',
  },
  {
    displayName: 'STORK',
    id: 'STORK',
    category: 'ANIMALS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/ANIMALS/STORK.png',
  },
  {
    displayName: 'SWAN',
    id: 'SWAN',
    category: 'ANIMALS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ANIMALS/SWAN.png',
  },
  {
    displayName: 'ALABAMA LICENSE PLATE',
    id: 'ALABAMA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/ALABAMA_LICENSE_PLATE.png',
  },
  {
    displayName: 'ALASKA LICENSE PLATE',
    id: 'ALASKA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/ALASKA_LICENSE_PLATE.png',
  },
  {
    displayName: 'ARIZONA LICENSE PLATE',
    id: 'ARIZONA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/ARIZONA_LICENSE_PLATE.png',
  },
  {
    displayName: 'ARKANSAS LICENSE PLATE',
    id: 'ARKANSAS_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/ARKANSAS_LICENSE_PLATE.png',
  },
  {
    displayName: 'CALIFORNIA LICENSE PLATE',
    id: 'CALIFORNIA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/CALIFORNIA_LICENSE_PLATE.png',
  },
  {
    displayName: 'COLORADO LICENSE PLATE',
    id: 'COLORADO_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/COLORADO_LICENSE_PLATE.png',
  },
  {
    displayName: 'CONNECTICUT LICENSE PLATE',
    id: 'CONNECTICUT_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/CONNECTICUT_LICENSE_PLATE.png',
  },
  {
    displayName: 'DELAWARE LICENSE PLATE',
    id: 'DELAWARE_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/DELAWARE_LICENSE_PLATE.png',
  },
  {
    displayName: 'FLORIDA LICENSE PLATE',
    id: 'FLORIDA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/FLORIDA_LICENSE_PLATE.png',
  },
  {
    displayName: 'GEORGIA LICENSE PLATE',
    id: 'GEORGIA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/GEORGIA_LICENSE_PLATE.png',
  },
  {
    displayName: 'HAWAII LICENSE PLATE',
    id: 'HAWAII_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/HAWAII_LICENSE_PLATE.png',
  },
  {
    displayName: 'IDAHO LICENSE PLATE',
    id: 'IDAHO_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/IDAHO_LICENSE_PLATE.png',
  },
  {
    displayName: 'ILLINOI LICENSE PLATE',
    id: 'ILLINOI_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/ILLINOI_LICENSE_PLATE.png',
  },
  {
    displayName: 'INDIANA LICENSE PLATE',
    id: 'INDIANA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/INDIANA_LICENSE_PLATE.png',
  },
  {
    displayName: 'IOWA LICENSE PLATE',
    id: 'IOWA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/IOWA_LICENSE_PLATE.png',
  },
  {
    displayName: 'KANSAS LICENSE PLATE',
    id: 'KANSAS_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/KANSAS_LICENSE_PLATE.png',
  },
  {
    displayName: 'KENTUCKY LICENSE PLATE',
    id: 'KENTUCKY_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/KENTUCKY_LICENSE_PLATE.png',
  },
  {
    displayName: 'LOUISIANA LICENSE PLATE',
    id: 'LOUISIANA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/LOUISIANA_LICENSE_PLATE.png',
  },
  {
    displayName: 'MAINE LICENSE PLATE',
    id: 'MAINE_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/MAINE_LICENSE_PLATE.png',
  },
  {
    displayName: 'MARYLAND LICENSE PLATE',
    id: 'MARYLAND_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/MARYLAND_LICENSE_PLATE.png',
  },
  {
    displayName: 'MASSACHUSETTS LICENSE PLATE',
    id: 'MASSACHUSETTS_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/MASSACHUSETTS_LICENSE_PLATE.png',
  },
  {
    displayName: 'MICHIGAN LICENSE PLATE',
    id: 'MICHIGAN_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/MICHIGAN_LICENSE_PLATE.png',
  },
  {
    displayName: 'MINNESOTA LICENSE PLATE',
    id: 'MINNESOTA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/MINNESOTA_LICENSE_PLATE.png',
  },
  {
    displayName: 'MISSISSIPPI LICENSE PLATE',
    id: 'MISSISSIPPI_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/MISSISSIPPI_LICENSE_PLATE.png',
  },
  {
    displayName: 'MISSOURI LICENSE PLATE',
    id: 'MISSOURI_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/MISSOURI_LICENSE_PLATE.png',
  },
  {
    displayName: 'MONTANA LICENSE PLATE',
    id: 'MONTANA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/MONTANA_LICENSE_PLATE.png',
  },
  {
    displayName: 'NEBRASKA LICENSE PLATE',
    id: 'NEBRASKA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/NEBRASKA_LICENSE_PLATE.png',
  },
  {
    displayName: 'NEVADA LICENSE PLATE',
    id: 'NEVADA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/NEVADA_LICENSE_PLATE.png',
  },
  {
    displayName: 'NEW HAMPSHIRE LICENSE PLATE',
    id: 'NEW_HAMPSHIRE_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/NEW_HAMPSHIRE_LICENSE_PLATE.png',
  },
  {
    displayName: 'NEW JERSEY LICENSE PLATE',
    id: 'NEW_JERSEY_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/NEW_JERSEY_LICENSE_PLATE.png',
  },
  {
    displayName: 'NEW MEXICO LICENSE PLATE',
    id: 'NEW_MEXICO_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/NEW_MEXICO_LICENSE_PLATE.png',
  },
  {
    displayName: 'NEW YORK LICENSE PLATE',
    id: 'NEW_YORK_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/NEW_YORK_LICENSE_PLATE.png',
  },
  {
    displayName: 'NORTH CAROLINA LICENSE PLATE',
    id: 'NORTH_CAROLINA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/NORTH_CAROLINA_LICENSE_PLATE.png',
  },
  {
    displayName: 'NORTH DAKOTA LICENSE PLATE',
    id: 'NORTH_DAKOTA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/NORTH_DAKOTA_LICENSE_PLATE.png',
  },
  {
    displayName: 'OHIO LICENSE PLATE',
    id: 'OHIO_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/OHIO_LICENSE_PLATE.png',
  },
  {
    displayName: 'OKLAHOMA LICENSE PLATE',
    id: 'OKLAHOMA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/OKLAHOMA_LICENSE_PLATE.png',
  },
  {
    displayName: 'OREGON LICENSE PLATE',
    id: 'OREGON_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/OREGON_LICENSE_PLATE.png',
  },
  {
    displayName: 'PENNSYLVANIA LICENSE PLATE',
    id: 'PENNSYLVANIA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/PENNSYLVANIA_LICENSE_PLATE.png',
  },
  {
    displayName: 'RHODE ISLAND LICENSE PLATE',
    id: 'RHODE_ISLAND_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/RHODE_ISLAND_LICENSE_PLATE.png',
  },
  {
    displayName: 'SOUTH CAROLINA LICENSE PLATE',
    id: 'SOUTH_CAROLINA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/SOUTH_CAROLINA_LICENSE_PLATE.png',
  },
  {
    displayName: 'SOUTH DAKOTA LICENSE PLATE',
    id: 'SOUTH_DAKOTA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/SOUTH_DAKOTA_LICENSE_PLATE.png',
  },
  {
    displayName: 'TENNESSEE LICENSE PLATE',
    id: 'TENNESSEE_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/TENNESSEE_LICENSE_PLATE.png',
  },
  {
    displayName: 'TEXAS LICENSE PLATE',
    id: 'TEXAS_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/TEXAS_LICENSE_PLATE.png',
  },
  {
    displayName: 'UTAH LICENSE PLATE',
    id: 'UTAH_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/UTAH_LICENSE_PLATE.png',
  },
  {
    displayName: 'VERGINIA LICENSE PLATE',
    id: 'VERGINIA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/VERGINIA_LICENSE_PLATE.png',
  },
  {
    displayName: 'VERMONT LICENSE PLATE',
    id: 'VERMONT_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/VERMONT_LICENSE_PLATE.png',
  },
  {
    displayName: 'WASHINGTON LICENSE PLATE',
    id: 'WASHINGTON_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/WASHINGTON_LICENSE_PLATE.png',
  },
  {
    displayName: 'WEST VIRGINIA LICENSE PLATE',
    id: 'WEST_VIRGINIA_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/WEST_VIRGINIA_LICENSE_PLATE.png',
  },
  {
    displayName: 'WISCONSIN LICENSE PLATE',
    id: 'WISCONSIN_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/WISCONSIN_LICENSE_PLATE.png',
  },
  {
    displayName: 'WYOMING LICENSE PLATE',
    id: 'WYOMING_LICENSE_PLATE',
    category: 'STATE LICENSE PLATES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STATE_LICENSE_PLATES/WYOMING_LICENSE_PLATE.png',
  },
  {
    displayName: 'BIRCH TREE',
    id: 'BIRCH_TREE',
    category: 'NATURE',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/NATURE/BIRCH_TREE.png',
  },
  {
    displayName: "BIRD'S NEST",
    id: 'BIRDS_NEST',
    category: 'NATURE',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/NATURE/BIRDS_NEST.png',
  },
  {
    displayName: 'CIRRUS CLOUDS',
    id: 'CIRRUS_CLOUDS',
    category: 'NATURE',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/NATURE/CIRRUS_CLOUDS.png',
  },
  {
    displayName: 'CONTRAILS',
    id: 'CONTRAILS',
    category: 'NATURE',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/NATURE/CONTRAILS.png',
  },
  {
    displayName: 'CUMULONIMBUS CLOUDS',
    id: 'CUMULONIMBUS_CLOUDS',
    category: 'NATURE',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/NATURE/CUMULONIMBUS_CLOUDS.png',
  },
  {
    displayName: 'CUMULUS CLOUDS',
    id: 'CUMULUS_CLOUDS',
    category: 'NATURE',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/NATURE/CUMULUS_CLOUDS.png',
  },
  {
    displayName: 'LAKE',
    id: 'LAKE',
    category: 'NATURE',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/NATURE/LAKE.png',
  },
  {
    displayName: 'POND',
    id: 'POND',
    category: 'NATURE',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/NATURE/POND.png',
  },
  {
    displayName: 'RAINBOW',
    id: 'RAINBOW',
    category: 'NATURE',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/NATURE/RAINBOW.png',
  },
  {
    displayName: 'WILLOW TREE',
    id: 'WILLOW_TREE',
    category: 'NATURE',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/NATURE/WILLOW_TREE.png',
  },
  {
    displayName: 'CHOPPED WOOD PILE',
    id: 'CHOPPED_WOOD_PILE',
    category: 'NEAR THE HOUSE',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/NEAR_THE_HOUSE/CHOPPED_WOOD_PILE.png',
  },
  {
    displayName: 'GRILL',
    id: 'GRILL',
    category: 'NEAR THE HOUSE',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/NEAR_THE_HOUSE/GRILL.png',
  },
  {
    displayName: 'LADDER',
    id: 'LADDER',
    category: 'NEAR THE HOUSE',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/NEAR_THE_HOUSE/LADDER.png',
  },
  {
    displayName: 'CAMPFIRE',
    id: 'CAMPFIRE',
    category: 'NEAR THE HOUSE',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/NEAR_THE_HOUSE/CAMPFIRE.png',
  },
  {
    displayName: 'PERSONAL SWIMMING POOL',
    id: 'PERSONAL_SWIMMING_POOL',
    category: 'NEAR THE HOUSE',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/NEAR_THE_HOUSE/PERSONAL_SWIMMING_POOL.png',
  },
  {
    displayName: 'PLAYGROUND',
    id: 'PLAYGROUND',
    category: 'NEAR THE HOUSE',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/NEAR_THE_HOUSE/PLAYGROUND.png',
  },
  {
    displayName: 'SATELLITE DISH',
    id: 'SATELLITE_DISH',
    category: 'NEAR THE HOUSE',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/NEAR_THE_HOUSE/SATELLITE_DISH.png',
  },
  {
    displayName: 'SOLAR PANELS',
    id: 'SOLAR_PANELS',
    category: 'NEAR THE HOUSE',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/NEAR_THE_HOUSE/SOLAR_PANELS.png',
  },
  {
    displayName: 'TREE HOUSE',
    id: 'TREE_HOUSE',
    category: 'NEAR THE HOUSE',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/NEAR_THE_HOUSE/TREE_HOUSE.png',
  },
  {
    displayName: 'WEATHER VANE',
    id: 'WEATHER_VANE',
    category: 'NEAR THE HOUSE',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/NEAR_THE_HOUSE/WEATHER_VANE.png',
  },
  {
    displayName: '5 MPH SIGN',
    id: '5_MPH_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/5_MPH_SIGN.png',
  },
  {
    displayName: '10 MPH SIGN',
    id: '10_MPH_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/10_MPH_SIGN.png',
  },
  {
    displayName: '15 MPH SIGN',
    id: '15_MPH_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/15_MPH_SIGN.png',
  },
  {
    displayName: '20 MPH SIGN',
    id: '20_MPH_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/20_MPH_SIGN.png',
  },
  {
    displayName: '25 MPH SIGN',
    id: '25_MPH_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/25_MPH_SIGN.png',
  },
  {
    displayName: '30 MPH SIGN',
    id: '30_MPH_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/30_MPH_SIGN.png',
  },
  {
    displayName: '35 MPH SIGN',
    id: '35_MPH_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/35_MPH_SIGN.png',
  },
  {
    displayName: '40 MPH SIGN',
    id: '40_MPH_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/40_MPH_SIGN.png',
  },
  {
    displayName: '45 MPH SIGN',
    id: '45_MPH_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/45_MPH_SIGN.png',
  },
  {
    displayName: '50 MPH SIGN',
    id: '50_MPH_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/50_MPH_SIGN.png',
  },
  {
    displayName: '55 MPH SIGN',
    id: '55_MPH_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/55_MPH_SIGN.png',
  },
  {
    displayName: '60 MPH SIGN',
    id: '60_MPH_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/60_MPH_SIGN.png',
  },
  {
    displayName: '65 MPH SIGN',
    id: '65_MPH_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/65_MPH_SIGN.png',
  },
  {
    displayName: '70 MPH SIGN',
    id: '70_MPH_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/70_MPH_SIGN.png',
  },
  {
    displayName: '75 MPH SIGN',
    id: '75_MPH_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/75_MPH_SIGN.png',
  },
  {
    displayName: 'CONSTRUCTION BARREL',
    id: 'CONSTRUCTION_BARREL',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/CONSTRUCTION_BARREL.png',
  },
  {
    displayName: 'CONSTRUCTION CONE',
    id: 'CONSTRUCTION_CONE',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/CONSTRUCTION_CONE.png',
  },
  {
    displayName: 'DO NOT ENTER SIGN',
    id: 'DO_NOT_ENTER_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/DO_NOT_ENTER_SIGN.png',
  },
  {
    displayName: 'MOTEL 6 SIGN',
    id: 'MOTEL_6_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/MOTEL_6_SIGN.png',
  },
  {
    displayName: 'MOTEL 8 SIGN',
    id: 'MOTEL_8_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/MOTEL_8_SIGN.png',
  },
  {
    displayName: 'NO U/TURNS SIGN',
    id: 'NO_U_TURNS_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/NO_U_TURNS_SIGN.png',
  },
  {
    displayName: 'ROAD WORK AHEAD SIGN',
    id: 'ROAD_WORK_AHEAD_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/ROAD_WORK_AHEAD_SIGN.png',
  },
  {
    displayName: 'STOP LIGHT WITH 5 LIGHTS',
    id: 'STOP_LIGHT_WITH_5_LIGHTS',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/STOP_LIGHT_WITH_5_LIGHTS.png',
  },
  {
    displayName: 'STOP SIGN',
    id: 'STOP_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/STOP_SIGN.png',
  },
  {
    displayName: 'YIELD SIGN',
    id: 'YIELD_SIGN',
    category: 'ROAD SIGNS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/ROAD_SIGNS/YIELD_SIGN.png',
  },
  {
    displayName: 'AMERICAN FLAG',
    id: 'AMERICAN_FLAG',
    category: 'ROADSIDE SIGHTINGS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/ROADSIDE/AMERICAN_FLAG.png',
  },
  {
    displayName: 'CONSTRUCTION WORKERS',
    id: 'CONSTRUCTION_WORKERS',
    category: 'ROADSIDE SIGHTINGS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/ROADSIDE/CONSTRUCTION_WORKERS.png',
  },
  {
    displayName: 'FLAG FROM ANOTHER COUNTRY',
    id: 'FLAG_FROM_ANOTHER_COUNTRY',
    category: 'ROADSIDE SIGHTINGS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ROADSIDE/FLAG_FROM_ANOTHER_COUNTRY.png',
  },
  {
    displayName: 'FURNITURE ON SIDE OF ROAD',
    id: 'FURNITURE_ON_SIDE_OF_ROAD',
    category: 'ROADSIDE SIGHTINGS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ROADSIDE/FURNITURE_ON_SIDE_OF_ROAD.png',
  },
  {
    displayName: 'JUNKYARD',
    id: 'JUNKYARD',
    category: 'ROADSIDE SIGHTINGS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/photos/ROADSIDE/JUNKYARD.png',
  },
  {
    displayName: 'PEOPLE FISHING',
    id: 'PEOPLE_FISHING',
    category: 'ROADSIDE SIGHTINGS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ROADSIDE/PEOPLE_FISHING.png',
  },
  {
    displayName: 'ROADKILL',
    id: 'ROADKILL',
    category: 'ROADSIDE SIGHTINGS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/ROADSIDE/ROADKILL.png',
  },
  {
    displayName: 'SHOE ON SIDE OF ROAD',
    id: 'SHOE_ON_SIDE_OF_ROAD',
    category: 'ROADSIDE SIGHTINGS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ROADSIDE/SHOE_ON_SIDE_OF_ROAD.png',
  },
  {
    displayName: 'SHOES OVER A WIRE',
    id: 'SHOES_OVER_A_WIRE',
    category: 'ROADSIDE SIGHTINGS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/ROADSIDE/SHOES_OVER_A_WIRE.png',
  },
  {
    displayName: 'TIRE ON SIDE OF ROAD',
    id: 'TIRE_ON_SIDE_OF_ROAD',
    category: 'ROADSIDE SIGHTINGS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/ROADSIDE/TIRE_ON_SIDE_OF_ROAD.png',
  },
  {
    displayName: 'BASEBALL FIELD',
    id: 'BASEBALL_FIELD',
    category: 'SPORTS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/SPORTS/BASEBALL_FIELD.png',
  },
  {
    displayName: 'BASEKTBALL HOOP',
    id: 'BASEKTBALL_HOOP',
    category: 'SPORTS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/SPORTS/BASEKTBALL_HOOP.png',
  },
  {
    displayName: 'BATTING CAGES',
    id: 'BATTING_CAGES',
    category: 'SPORTS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/SPORTS/BATTING_CAGES.png',
  },
  {
    displayName: 'FOOTBALL FIELD',
    id: 'FOOTBALL_FIELD',
    category: 'SPORTS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/SPORTS/FOOTBALL_FIELD.png',
  },
  {
    displayName: 'GOLF COURSE',
    id: 'GOLF_COURSE',
    category: 'SPORTS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/SPORTS/GOLF_COURSE.png',
  },
  {
    displayName: 'PUTT/PUTT COURSE',
    id: 'PUTT_PUTT_COURSE',
    category: 'SPORTS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/SPORTS/PUTT_PUTT_COURSE.png',
  },
  {
    displayName: 'RACE TRACK',
    id: 'RACE_TRACK',
    category: 'SPORTS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/SPORTS/RACE_TRACK.png',
  },
  {
    displayName: 'SOCCER FIELD',
    id: 'SOCCER_FIELD',
    category: 'SPORTS',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/SPORTS/SOCCER_FIELD.png',
  },
  {
    displayName: 'TENNIS COURT',
    id: 'TENNIS_COURT',
    category: 'SPORTS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/SPORTS/TENNIS_COURT.png',
  },
  {
    displayName: 'VOLLEYBALL NET',
    id: 'VOLLEYBALL_NET',
    category: 'SPORTS',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/SPORTS/VOLLEYBALL_NET.png',
  },
  {
    displayName: 'BARN',
    id: 'BARN',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STRUCTURES/BARN.png',
  },
  {
    displayName: 'BROKEN DOWN BARN',
    id: 'BROKEN_DOWN_BARN',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STRUCTURES/BROKEN_DOWN_BARN.png',
  },
  {
    displayName: 'CARNIVAL / FAIRGROUNDS',
    id: 'CARNIVAL_FAIRGROUNDS',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/STRUCTURES/CARNIVAL_FAIRGROUNDS.png',
  },
  {
    displayName: 'CEMETARY',
    id: 'CEMETARY',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/STRUCTURES/CEMETARY.png',
  },
  {
    displayName: 'CHURCH',
    id: 'CHURCH',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STRUCTURES/CHURCH.png',
  },
  {
    displayName: 'FIRE HYDRANT',
    id: 'FIRE_HYDRANT',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STRUCTURES/FIRE_HYDRANT.png',
  },
  {
    displayName: 'FIRE STATION',
    id: 'FIRE_STATION',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/STRUCTURES/FIRE_STATION.png',
  },
  {
    displayName: 'FOUNTAIN',
    id: 'FOUNTAIN',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/photos/STRUCTURES/FOUNTAIN.png',
  },
  {
    displayName: 'GAZEEBO',
    id: 'GAZEEBO',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/STRUCTURES/GAZEEBO.png',
  },
  {
    displayName: 'HAY BALES',
    id: 'HAY_BALES',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STRUCTURES/HAY_BALES.png',
  },
  {
    displayName: 'LOG CABIN',
    id: 'LOG_CABIN',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/STRUCTURES/LOG_CABIN.png',
  },
  {
    displayName: 'OIL WELL',
    id: 'OIL_WELL',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/STRUCTURES/OIL_WELL.png',
  },
  {
    displayName: 'MASQUE',
    id: 'MASQUE',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/photos/STRUCTURES/MASQUE.png',
  },
  {
    displayName: 'PORTO-POTTY',
    id: 'PORTO_POTTY',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STRUCTURES/PORTO_POTTY.png',
  },
  {
    displayName: 'SALT STORAGE DOME',
    id: 'SALT_STORAGE_DOME',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STRUCTURES/SALT_STORAGE_DOME.png',
  },
  {
    displayName: 'SILO',
    id: 'SILO',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STRUCTURES/SILO.png',
  },
  {
    displayName: 'SYNAGOGUE',
    id: 'SYNAGOGUE',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/STRUCTURES/SYNAGOGUE.png',
  },
  {
    displayName: 'TRAILER PARK',
    id: 'TRAILER_PARK',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/STRUCTURES/TRAILER_PARK.png',
  },
  {
    displayName: 'WATER PARK',
    id: 'WATER_PARK',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/STRUCTURES/WATER_PARK.png',
  },
  {
    displayName: 'WATER TOWER',
    id: 'WATER_TOWER',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STRUCTURES/WATER_TOWER.png',
  },
  {
    displayName: 'WATER WELL',
    id: 'WATER_WELL',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/STRUCTURES/WATER_WELL.png',
  },
  {
    displayName: 'WINDMILL',
    id: 'WINDMILL',
    category: 'STRUCTURES & PLACES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/STRUCTURES/WINDMILL.png',
  },
  {
    displayName: 'AIRPLANE',
    id: 'AIRPLANE',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/VEHICLES/AIRPLANE.png',
  },
  {
    displayName: 'AMAZON TRUCK',
    id: 'AMAZON_TRUCK',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/AMAZON_TRUCK.png',
  },
  {
    displayName: 'AMBULANCE',
    id: 'AMBULANCE',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/AMBULANCE.png',
  },
  {
    displayName: 'ANIMAL TRANSPORT TRUCK, EMPTY',
    id: 'ANIMAL_TRANSPORT_TRUCK_EMPTY',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/ANIMAL_TRANSPORT_TRUCK_EMPTY.png',
  },
  {
    displayName: 'ANIMAL TRANSPORT TRUCK, FULL',
    id: 'ANIMAL_TRANSPORT_TRUCK_FULL',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/VEHICLES/ANIMAL_TRANSPORT_TRUCK_FULL.png',
  },
  {
    displayName: 'ARMY VEHICLE',
    id: 'ARMY_VEHICLE',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/VEHICLES/ARMY_VEHICLE.png',
  },
  {
    displayName: 'ATV / 4/WHEELER',
    id: 'ATV_4_WHEELER',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/VEHICLES/ATV_4_WHEELER.png',
  },
  {
    displayName: 'BICYCLE',
    id: 'BICYCLE',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/VEHICLES/BICYCLE.png',
  },
  {
    displayName: 'BLACK TRUCK WITH BLACK TRAILER',
    id: 'BLACK_TRUCK_WITH_BLACK_TRAILER',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/BLACK_TRUCK_WITH_BLACK_TRAILER.png',
  },
  {
    displayName: 'BLUE TRUCK WITH BLUE TRAILER',
    id: 'BLUE_TRUCK_WITH_BLUE_TRAILER',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/BLUE_TRUCK_WITH_BLUE_TRAILER.png',
  },
  {
    displayName: 'BOAT',
    id: 'BOAT',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/VEHICLES/BOAT.png',
  },
  {
    displayName: 'CONSTRUCTION CRANE',
    id: 'CONSTRUCTION_CRANE',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/VEHICLES/CONSTRUCTION_CRANE.png',
  },
  {
    displayName: 'CONVERTIBLE WITH TOP DOWN',
    id: 'CONVERTIBLE_WITH_TOP_DOWN',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/CONVERTIBLE_WITH_TOP_DOWN.png',
  },
  {
    displayName: 'FEDEX TRUCK',
    id: 'FEDEX_TRUCK',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/FEDEX_TRUCK.png',
  },
  {
    displayName: 'FIRE TRUCK',
    id: 'FIRE_TRUCK',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/VEHICLES/FIRE_TRUCK.png',
  },
  {
    displayName: 'GOLF CART',
    id: 'GOLF_CART',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/VEHICLES/GOLF_CART.png',
  },
  {
    displayName: 'GREEN TRUCK WITH A GREEN TRAILER',
    id: 'GREEN_TRUCK_WITH_A_GREEN_TRAILER',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/GREEN_TRUCK_WITH_A_GREEN_TRAILER.png',
  },
  {
    displayName: 'HELICOPTER',
    id: 'HELICOPTER',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/VEHICLES/HELICOPTER.png',
  },
  {
    displayName: 'HORSE & BUGGY',
    id: 'HORSE_&_BUGGY',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/VEHICLES/HORSE_BUGGY.png',
  },
  {
    displayName: 'ICE CREAM TRUCK',
    id: 'ICE_CREAM_TRUCK',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/VEHICLES/ICE_CREAM_TRUCK.png',
  },
  {
    displayName: 'LIMOUSINE',
    id: 'LIMOUSINE',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/LIMOUSINE.png',
  },
  {
    displayName: 'MOBILE HOME / RV',
    id: 'MOBILE_HOME_RV',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/VEHICLES/MOBILE_HOME_RV.png',
  },
  {
    displayName: 'MOTORCYCLE',
    id: 'MOTORCYCLE',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/VEHICLES/MOTORCYCLE.png',
  },
  {
    displayName: 'ORANGE CAR',
    id: 'ORANGE_CAR',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/ORANGE_CAR.png',
  },
  {
    displayName: 'ORANGE TRUCK WITH ORANGE TRAILER',
    id: 'ORANGE_TRUCK_WITH_ORANGE_TRAILER',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/ORANGE_TRUCK_WITH_ORANGE_TRAILER.png',
  },
  {
    displayName: 'PENSKE TRUCK',
    id: 'PENSKE_TRUCK',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/VEHICLES/PENSKE_TRUCK.png',
  },
  {
    displayName: 'PINK CAR',
    id: 'PINK_CAR',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/VEHICLES/PINK_CAR.png',
  },
  {
    displayName: 'POLICE CAR',
    id: 'POLICE_CAR',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/VEHICLES/POLICE_CAR.png',
  },
  {
    displayName: 'RACING STRIPED CAR',
    id: 'RACING_STRIPED_CAR',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/RACING_STRIPED_CAR.png',
  },
  {
    displayName: 'REAR/VIEW MIRROR DICE',
    id: 'REAR_VIEW_MIRROR_DICE',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/VEHICLES/REAR_VIEW_MIRROR_DICE.png',
  },
  {
    displayName: 'RED TRUCK WITH RED TRAILER',
    id: 'RED_TRUCK_WITH_RED_TRAILER',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/RED_TRUCK_WITH_RED_TRAILER.png',
  },
  {
    displayName: 'SCHOOL BUS (FLAT FRONT)',
    id: 'SCHOOL_BUS_FLAT_FRONT',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/SCHOOL_BUS_FLAT_FRONT.png',
  },
  {
    displayName: 'SCHOOL BUS (REGULAR)',
    id: 'SCHOOL_BUS_REGULAR',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/VEHICLES/SCHOOL_BUS_REGULAR.png',
  },
  {
    displayName: 'SCOOTER',
    id: 'SCOOTER',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/photos/VEHICLES/SCOOTER.png',
  },
  {
    displayName: 'SPINNING CEMENT TRUCK',
    id: 'SPINNING_CEMENT_TRUCK',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/VEHICLES/SPINNING_CEMENT_TRUCK.png',
  },
  {
    displayName: 'STATION WAGON',
    id: 'STATION_WAGON',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/VEHICLES/STATION_WAGON.png',
  },
  {
    displayName: 'TARGET TRUCK',
    id: 'TARGET_TRUCK',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/VEHICLES/TARGET_TRUCK.png',
  },
  {
    displayName: 'TAXI',
    id: 'TAXI',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/VEHICLES/TAXI.png',
  },
  {
    displayName: 'TESLA',
    id: 'TESLA',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/photos/VEHICLES/TESLA.png',
  },
  {
    displayName: 'TRACTOR',
    id: 'TRACTOR',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/VEHICLES/TRACTOR.png',
  },
  {
    displayName: 'TRUCK CARRYING A TRUCK',
    id: 'TRUCK_CARRYING_A_TRUCK',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/TRUCK_CARRYING_A_TRUCK.png',
  },
  {
    displayName: 'TRUCK CARRYING CARS',
    id: 'TRUCK_CARRYING_CARS',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/TRUCK_CARRYING_CARS.png',
  },
  {
    displayName: 'TRUCK WITH 2 TRAILERS',
    id: 'TRUCK_WITH_2_TRAILERS',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/TRUCK_WITH_2_TRAILERS.png',
  },
  {
    displayName: 'TRUCK WITH 3 TRAILERS',
    id: 'TRUCK_WITH_3_TRAILERS',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Hard,
    checked: false,
    imageUrl: 'images/VEHICLES/TRUCK_WITH_3_TRAILERS.png',
  },
  {
    displayName: 'TRUCK WITH NO TRAILER',
    id: 'TRUCK_WITH_NO_TRAILER',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/VEHICLES/TRUCK_WITH_NO_TRAILER.png',
  },
  {
    displayName: 'UHAUL',
    id: 'UHAUL',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/UHAUL.png',
  },
  {
    displayName: 'VOLKSWAGON BUG',
    id: 'VOLKSWAGON_BUG',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/VEHICLES/VOLKSWAGON_BUG.png',
  },
  {
    displayName: 'WALMART TRUCK',
    id: 'WALMART_TRUCK',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/VEHICLES/WALMART_TRUCK.png',
  },
  {
    displayName: 'YELLOW CAR',
    id: 'YELLOW_CAR',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/YELLOW_CAR.png',
  },
  {
    displayName: 'YELLOW TRUCK WITH YELLOW TRAILER',
    id: 'YELLOW_TRUCK_WITH_YELLOW_TRAILER',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Medium,
    checked: false,
    imageUrl: 'images/VEHICLES/YELLOW_TRUCK_WITH_YELLOW_TRAILER.png',
  },
  {
    displayName: 'WHITE TRUCK WITH WHITE TRAILER',
    id: 'WHITE_TRUCK_WITH_WHITE_TRAILER',
    category: 'VEHICLES',
    difficulty: GameDifficulty.Easy,
    checked: false,
    imageUrl: 'images/VEHICLES/WHITE_TRUCK_WITH_WHITE_TRAILER.png',
  },
];
