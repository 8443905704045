import { MaterialIcons } from '@expo/vector-icons';
import {
  Heading,
  HStack,
  VStack,
  IconButton,
  Icon,
  FlatList,
  Center,
} from 'native-base';
import { FC, MutableRefObject } from 'react';
import Drawer from 'react-native-drawer';
import { useSelector } from 'react-redux';
import { RootState } from '../state/store';
import { RootStackParamList, navigationRef } from './navigation';
import { presentToast } from '../firebase-utilities';
import { fireAuth } from '../backend';
export type NavDrawerProps = {
  drawerRef: MutableRefObject<Drawer>;
};

export type FlatListDataItem = {
  url?: keyof RootStackParamList;
  onClick: () => void;
  icon: string;
  display: string;
  displaySubTitle: string;
};

export const NavDrawer: FC<NavDrawerProps> = ({ drawerRef }) => {
  const currentUserInStore = useSelector(
    (state: RootState) => state?.appConfiguration?.currentUser
  );
  const debugMode = useSelector(
    (state: RootState) => state.appConfiguration?.debugMode ?? false
  );
  const flatListData: FlatListDataItem[] =
    currentUserInStore?.isAnonymous ?? true
      ? [
          {
            url: 'home',
            onClick: null,
            icon: 'home',
            display: 'Home',
            displaySubTitle: null,
          },
          {
            url: 'loginregister',
            onClick: null,
            icon: 'login',
            display: 'Login / Register',
            displaySubTitle: null,
          },
          {
            url: 'history',
            onClick: null,
            icon: 'history',
            display: 'Game History',
            displaySubTitle: 'Login / Register to view',
          },
          {
            url: 'deletemydata',
            onClick: null,
            icon: 'delete',
            display: 'Manage Data',
            displaySubTitle: null,
          },
        ]
      : [
          {
            url: 'home',
            onClick: null,
            icon: 'home',
            display: 'Home',
            displaySubTitle: null,
          },
          {
            url: null,
            onClick: () =>
              fireAuth
                .signOut()
                .then(() => drawerRef.current?.close())
                .catch((err) => {
                  console.error(err);
                  presentToast(
                    'There was an error logging out! Please try again later',
                    debugMode,
                    '#be123c',
                    5000
                  );
                }),
            icon: 'logout',
            display: 'Logout',
            displaySubTitle: null,
          },
          {
            url: 'history',
            onClick: null,
            icon: 'history',
            display: 'Game History',
            displaySubTitle: null,
          },
          {
            url: 'deletemydata',
            onClick: null,
            icon: 'delete',
            display: 'Manage Data',
            displaySubTitle: null,
          },
        ];
  const handlePress = (item: FlatListDataItem) => {
    if (item.url == null) {
      item?.onClick();
      return;
    }
    drawerRef.current?.close();
    navigationRef.navigate(item.url);
  };
  return (
    <Center height="100%">
      <VStack
        height="100%"
        _dark={{ bg: '#000000' }}
        alignItems="center"
        width="100%"
      >
        <HStack
          _light={{ bg: 'primary.900' }}
          _dark={{ bg: 'secondary.500' }}
          px={2}
          py={4}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          paddingLeft={5}
        >
          <Heading size="lg" fontWeight="bold" color="white" textAlign="center">
            Find It!
          </Heading>
          <IconButton
            icon={
              <Icon size="md" as={MaterialIcons} name="close" color="white" />
            }
            onPress={() => drawerRef?.current?.close()}
          />
        </HStack>
        <FlatList
          width="100%"
          data={flatListData}
          renderItem={({ item }) => {
            return (
              <HStack alignSelf="flex-start" alignItems="center" padding={5}>
                <Icon
                  size="xl"
                  as={MaterialIcons}
                  name={item.icon}
                  _dark={{ color: 'white' }}
                  _light={{ color: 'black' }}
                  onPress={() => handlePress(item)}
                  marginRight={5}
                />
                <VStack>
                  <Heading onPress={() => handlePress(item)} size="lg">
                    {item.display}
                  </Heading>
                  {item.displaySubTitle != null ? (
                    <Heading onPress={() => handlePress(item)} size="sm">
                      {item.displaySubTitle}
                    </Heading>
                  ) : null}
                </VStack>
              </HStack>
            );
          }}
        />
      </VStack>
    </Center>
  );
};
