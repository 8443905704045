import type { ColorMode, StorageManager } from 'native-base';
import {
  getItemFromStorageAsync,
  setItemInStorageAsync,
} from '../firebase-utilities';

export const findItTheme = {
  primary: {
    50: '#fffde9',
    100: '#fff9c9',
    200: '#fff5a6',
    300: '#fff084',
    400: '#fdeb6a',
    500: '#fbe654',
    600: '#fdd953',
    700: '#f9c24c',
    800: '#f5ab44',
    900: '#ed8637',
  },
  secondary: {
    50: '#e1f2f1',
    100: '#b4dedb',
    200: '#83c9c4',
    300: '#54b4ac',
    400: '#35a39a',
    500: '#229388',
    600: '#1f867b',
    700: '#1c766b',
    800: '#1a665c',
    900: '#154a40',
  },
};

export const colorModeManager: StorageManager = {
  get: async () => {
    try {
      let val = await getItemFromStorageAsync('findit-app-color-mode');
      return val === 'dark' ? 'dark' : 'light';
    } catch (e) {
      console.log(e);
      return 'light';
    }
  },
  set: async (value: ColorMode) => {
    try {
      await setItemInStorageAsync('findit-app-color-mode', value ?? 'light');
    } catch (e) {
      console.log(e);
    }
  },
};
