import Constants from 'expo-constants';
const schemaVersion = Constants?.expoConfig?.extra?.schemaVersion;
import { checkboxes } from './checkboxes';
import {
  collection,
  doc,
  getDocs,
  query,
  runTransaction,
  Timestamp,
  serverTimestamp,
  Firestore,
  where,
} from 'firebase/firestore';
import {
  IGame,
  Player,
  GameBox,
  GameDifficulty,
  GameBoxCategory,
  AllGameBoxCategories,
} from './game.types';

export class Game implements IGame {
  players: Player[];
  playerIds: string[];
  checkBoxes: GameBox[];
  createdDate: Timestamp;
  createdBy: string;

  constructor(
    createdBy: string,
    players: Player[] = [],
    checkBoxes: GameBox[] = []
  ) {
    this.checkBoxes =
      checkBoxes && (checkBoxes?.length ?? 0) > 0
        ? checkBoxes
        : Game.generateCheckBoxes();
    this.players = players;
    this.playerIds = players.map((player) => player.userId);
    this.createdDate = serverTimestamp() as Timestamp;
    this.createdBy = createdBy;
  }

  static generateCheckBoxes(): GameBox[] {
    return checkboxes;
  }

  static async getCheckBoxesFromFirebase(
    fireStore: Firestore,
    retry: boolean,
    selectedCheckBoxCategories: GameBoxCategory[] = [],
    selectedDifficulty: GameDifficulty = GameDifficulty.Easy
  ): Promise<GameBox[]> {
    try {
      const difficultyAsNumber = selectedDifficulty as Number;
      const checkboxMetaDataQuery = query(
        collection(fireStore, `checkboxmetadata${schemaVersion}`),
        where('category', 'in', selectedCheckBoxCategories),
        where('difficulty', '<=', difficultyAsNumber)
      );
      const querySnapshot = await getDocs(checkboxMetaDataQuery);
      if (retry && querySnapshot.empty) {
        try {
          const checkboxMetaDataCollectionRef = collection(
            fireStore,
            `checkboxmetadata${schemaVersion}`
          );
          await runTransaction(fireStore, async (transaction) => {
            const generatedCheckboxes = Game.generateCheckBoxes();
            for (const checkbox of generatedCheckboxes) {
              const checkboxDocumentRef = doc(
                checkboxMetaDataCollectionRef,
                checkbox.id
              );
              transaction.set(checkboxDocumentRef, checkbox);
            }
          });
        } catch (e) {
          console.log('seedCheckboxes failed with', e);
        }
        return this.getCheckBoxesFromFirebase(
          fireStore,
          false,
          selectedCheckBoxCategories,
          selectedDifficulty
        );
      }
      return querySnapshot.docs.map((document) => {
        const docData = document.data();
        if (docData.composedOf != null) {
          return {
            id: document.id,
            displayName: docData.displayName,
            category: docData.category,
            checked: docData.checked,
            composedOf: docData.composedOf,
            imageUrl: docData.imageUrl ?? '',
          } as GameBox;
        }
        return {
          id: document.id,
          displayName: docData.displayName,
          category: docData.category,
          checked: docData.checked,
          imageUrl: docData.imageUrl ?? '',
        } as GameBox;
      });
    } catch (error) {
      console.log('Error getting documents', error);
    }
    return Game.generateCheckBoxes().filter(
      (checkbox) =>
        selectedCheckBoxCategories.includes(checkbox.category) &&
        checkbox.difficulty === selectedDifficulty
    );
  }

  static async createGameAsync(
    firestore: Firestore,
    createdBy: string,
    players: Player[] = [],
    checkBoxes: GameBox[] = [],
    selectedCheckBoxCategories: GameBoxCategory[] = [...AllGameBoxCategories],
    selectedDifficulty: GameDifficulty = GameDifficulty.Easy
  ) {
    if (!((checkBoxes?.length ?? 0) > 0)) {
      checkBoxes = await Game.getCheckBoxesFromFirebase(
        firestore,
        true,
        selectedCheckBoxCategories,
        selectedDifficulty
      );
    }
    const game = new Game(createdBy, players, checkBoxes);
    return game;
  }

  asFirebaseCollectionInterface(): IGame {
    return {
      players: this.players,
      playerIds: this.playerIds,
      createdDate: this.createdDate,
      createdBy: this.createdBy,
    };
  }
}
