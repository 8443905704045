import { MaterialIcons } from '@expo/vector-icons';
import {
  Modal,
  Button,
  Icon,
  Heading,
  VStack,
  Text,
  Center,
} from 'native-base';
import { FC, useEffect, useState } from 'react';
import { RootState } from '../../state/store';
import { useSelector } from 'react-redux';
import { navigationRef } from '../navigation';
import { useDispatch } from 'react-redux';
import {
  loadCheckboxMetaData,
  setEndGameModalVisibility,
} from '../../state/game/game-slice';
import { archiveGame } from '../../game-utilities';
import { presentToast } from '../../firebase-utilities';
import { Loader } from '../loader';
import { Timestamp } from 'firebase/firestore';

const EndGameModal: FC = () => {
  const dispatch = useDispatch();
  const modalVisible = useSelector(
    (state: RootState) => state.game.endGameModalVisible ?? false
  );
  const [loadingGameStats, setLoadingGameStats] = useState(true);
  const [displayMessage, setDisplayMessage] = useState('');
  const currentGameId = useSelector(
    (state: RootState) =>
      state?.appConfiguration?.currentUserData?.currentGameId
  );
  const currentPlayerCount = useSelector(
    (state: RootState) => state?.game?.game?.playerIds?.length ?? 1
  );
  const [endGameForAllPlayersConfirmed, setEndGameForAllPlayersConfirmed] =
    useState(currentPlayerCount !== 1);
  useEffect(() => {
    if (
      modalVisible &&
      endGameForAllPlayersConfirmed &&
      currentGameId != null
    ) {
      archiveGame(currentGameId)
        .then(({ game, checkboxes }) => {
          setLoadingGameStats(false);
          const totalCheckedCheckboxes = checkboxes.filter(
            (checkbox) => checkbox.checked
          ).length;
          const totalCheckboxes = checkboxes.length;
          const totalTimeInSeconds =
            (game.gameArchivedDate ?? Timestamp.now()).seconds -
            game.createdDate.seconds;
          const totalHours = Math.floor(totalTimeInSeconds / 3600);
          const remainderMinutes = Math.floor((totalTimeInSeconds % 3600) / 60);
          const displayMessage = `Game Ended in ${totalHours} hours, ${remainderMinutes} minutes. \n${totalCheckedCheckboxes} out of ${totalCheckboxes} items found`;
          setDisplayMessage(displayMessage);
        })
        .catch((err) => {
          console.error('error archiving game', err);
          presentToast(
            'There was an error ending game, please try again later'
          );
        });
    }
  }, [endGameForAllPlayersConfirmed, modalVisible]);
  return (
    <Modal
      isOpen={modalVisible}
      onClose={() => dispatch(setEndGameModalVisibility({ visible: false }))}
      size="full"
      maxWidth={380}
      marginLeft="auto"
      marginRight="auto"
      alignContent="center"
    >
      {endGameForAllPlayersConfirmed ? (
        <Modal.Content height={510}>
          <Modal.Body>
            <VStack space={1} alignItems="center" height="100%">
              <Heading
                size="lg"
                textAlign="center"
                _dark={{ color: 'primary.900' }}
                _light={{ color: 'secondary.500' }}
              >
                Game ended
              </Heading>
              <Icon
                size="200"
                as={MaterialIcons}
                name="check-circle-outline"
                _dark={{ color: 'primary.900' }}
                _light={{ color: 'secondary.500' }}
              />
              <Center marginBottom={10}>
                {loadingGameStats ? (
                  <Loader />
                ) : (
                  <Text textAlign="center" fontWeight="bold">
                    {displayMessage}
                  </Text>
                )}
              </Center>
              <Button.Group
                direction="column"
                width="full"
                size="full"
                maxWidth={500}
                _text={{ fontSize: 15 }}
                alignSelf={'flex-end'}
              >
                <Button
                  onPress={() => {
                    dispatch(setEndGameModalVisibility({ visible: false }));
                    navigationRef.navigate('home');
                    navigationRef.navigate('startnew');
                  }}
                  _dark={{ bg: 'primary.900' }}
                  _light={{ bg: 'secondary.500' }}
                  height={41}
                >
                  Start A New Game
                </Button>
                <Button
                  bg="gray.700"
                  onPress={() => {
                    dispatch(setEndGameModalVisibility({ visible: false }));
                    navigationRef.navigate('join');
                  }}
                  height={41}
                >
                  Join A Game
                </Button>
                <Button
                  bg="gray.700"
                  onPress={() => {
                    dispatch(setEndGameModalVisibility({ visible: false }));
                    navigationRef.navigate('home');
                  }}
                  height={41}
                >
                  Exit to Menu
                </Button>
              </Button.Group>
            </VStack>
          </Modal.Body>
        </Modal.Content>
      ) : (
        <Modal.Content height={210}>
          <Modal.Body>
            <VStack space={1} alignItems="center" height="100%">
              <Heading
                size="lg"
                textAlign="center"
                _dark={{ color: 'primary.900' }}
                _light={{ color: 'secondary.500' }}
                marginBottom={50}
              >
                End Game for all players?
              </Heading>
              <Button.Group
                direction="column"
                width="full"
                size="full"
                maxWidth={500}
                _text={{ fontSize: 15 }}
                alignSelf={'flex-end'}
              >
                <Button
                  onPress={() => setEndGameForAllPlayersConfirmed(true)}
                  _dark={{ bg: 'primary.900' }}
                  _light={{ bg: 'secondary.500' }}
                  height={41}
                >
                  Yes, end game
                </Button>
                <Button
                  bg="gray.700"
                  onPress={() =>
                    dispatch(setEndGameModalVisibility({ visible: false }))
                  }
                  height={41}
                >
                  No, return to game
                </Button>
              </Button.Group>
            </VStack>
          </Modal.Body>
        </Modal.Content>
      )}
    </Modal>
  );
};
export default EndGameModal;
