import { Center, View, Image } from 'native-base';
import { GameBox } from '../models/game.types';
import { Loader } from './loader';
import { SvgUri } from 'react-native-svg';
import { Platform } from 'react-native';

export type DisplayImageScrollViewProps = {
  itemCurrentlyDisplayed: GameBox | null;
  itemCurrentlyDisplayedUri: string | null;
};
const DisplayImageScrollView: React.FC<DisplayImageScrollViewProps> = ({
  itemCurrentlyDisplayedUri,
  itemCurrentlyDisplayed,
}) => {
  return itemCurrentlyDisplayedUri != null ? (
    <View flex={1} width={250}>
      <Center width={250}>
        <Image
          width="full"
          height={250}
          maxWidth={250}
          resizeMode="contain"
          source={{
            uri: itemCurrentlyDisplayedUri,
          }}
          alt={itemCurrentlyDisplayed.displayName}
        />
      </Center>
    </View>
  ) : (
    <Center width={250} height={250}>
      <Loader />
    </Center>
  );
};

export default DisplayImageScrollView;
