import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Button, Text, Checkbox, Heading, VStack, Center } from 'native-base';
import { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootStackParamList } from '../components/navigation';
import { RootState } from '../state/store';
import { deleteMyData, presentToast } from '../firebase-utilities';
import * as Clipboard from 'expo-clipboard';
import { Loader } from '../components/loader';
import { fireAuth } from '../backend';

type DeleteMyDataPageProps = NativeStackScreenProps<
  RootStackParamList,
  'deletemydata'
>;
export const DeleteMyDataPage: FC<DeleteMyDataPageProps> = ({
  route,
  navigation,
}) => {
  const debugMode = useSelector(
    (state: RootState) => state?.appConfiguration?.debugMode ?? false
  );

  const currentUserId = useSelector(
    (state: RootState) => state?.appConfiguration?.currentUser?.uid
  );
  const [imSure, setImSure] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteData = async () => {
    try {
      setLoading(true);
      await deleteMyData();
      presentToast('You data has been deleted');
      await new Promise((resolve) => setTimeout(resolve, 5000));
      navigation.navigate('home');
    } catch (e: unknown) {
      presentToast(
        `Something went wrong, please contact the finditgame@gmail.com to get your data deleted!`,
        debugMode,
        '#be123c',
        10000
      );
    }
    setLoading(false);
  };
  return (
    <VStack alignItems="center" paddingLeft={5} paddingRight={5}>
      <Heading
        size="lg"
        textAlign="center"
        _light={{ color: 'secondary.500' }}
        _dark={{ color: 'primary.900' }}
        marginBottom={5}
      >
        Click here to delete your data (this is irreversible!)
      </Heading>
      {loading && (
        <Center>
          <Loader />
        </Center>
      )}
      {!loading && (
        <>
          <Checkbox
            colorScheme="orange"
            value="delete_my_data"
            isChecked={imSure}
            onChange={(value) => setImSure(value)}
            accessibilityLabel={`Check to delete data`}
          >
            I understand, I would like to continue
          </Checkbox>
          <Button.Group
            direction="column"
            width="full"
            maxWidth={500}
            _text={{ fontSize: 15 }}
          >
            <Button
              isDisabled={!imSure}
              _dark={{ bg: 'primary.900' }}
              _light={{ bg: 'secondary.500' }}
              onPress={() => {
                if (imSure) {
                  deleteData();
                }
              }}
            >
              DELETE MY DATA
            </Button>
          </Button.Group>
        </>
      )}
      <Text>UserId: {currentUserId ?? ''}</Text>
    </VStack>
  );
};
