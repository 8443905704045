import { Box, Checkbox, FlatList, Icon, Text } from 'native-base';
import { FC, useEffect, useState } from 'react';
import { MaterialIcons } from '@expo/vector-icons';
import { GameBoxCategory } from '../models/game.types';
import { AllGameBoxCategories as allGameBoxCategories } from '../models/game.types';
import { TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import {
  setComposeNewCategoryModalCategory,
  setComposeNewCategoryModalVisibility,
} from '../state/app/app-configuration-slice';

export type ComposeNewCategoriesContentProps = {
  categoriesCallback: (categories: GameBoxCategory[]) => void;
  preSelectedCategories?: GameBoxCategory[];
};
export const ComposeNewCategoriesContent: FC<
  ComposeNewCategoriesContentProps
> = ({ categoriesCallback, preSelectedCategories }) => {
  const dispatch = useDispatch();
  const categoryCheckboxObjectTracker = allGameBoxCategories.map((category) => {
    const [categoryChecked, setCategoryChecked] = useState(
      preSelectedCategories.includes(category)
    );
    return {
      category,
      categoryChecked,
      setCategoryChecked,
    };
  });
  const categoriesChecked = categoryCheckboxObjectTracker.map(
    (categoryCheckboxObject) => categoryCheckboxObject.categoryChecked
  );
  useEffect(() => {
    categoriesCallback(
      categoryCheckboxObjectTracker
        .filter(
          (categoryCheckboxObject) => categoryCheckboxObject.categoryChecked
        )
        .map((categoryCheckboxObject) => categoryCheckboxObject.category)
    );
  }, categoriesChecked);
  const iconButtonPressed = (category: GameBoxCategory) => {
    dispatch(setComposeNewCategoryModalCategory({ category }));
    dispatch(setComposeNewCategoryModalVisibility({ visible: true }));
  };
  return (
    <>
      <FlatList
        data={categoryCheckboxObjectTracker}
        width="full"
        _contentContainerStyle={{
          justifyContent: 'space-between',
        }}
        renderItem={({ item }) => (
          <Box flexDirection="row" padding={1} alignItems="center">
            <Text
              flexGrow={1}
              onPress={() => item.setCategoryChecked(!item.categoryChecked)}
            >
              {item.category}
            </Text>
            <TouchableOpacity
              onPress={() => iconButtonPressed(item.category)}
              style={{ marginRight: 2 }}
            >
              <Icon
                size="lg"
                as={MaterialIcons}
                name="preview"
                _light={{ color: 'black' }}
                _dark={{ color: 'white' }}
              />
            </TouchableOpacity>
            <Checkbox
              colorScheme="orange"
              value={`category_${item.category}`}
              isChecked={item.categoryChecked}
              onChange={(value) => item.setCategoryChecked(value)}
              accessibilityLabel={`category checkbox for ${item.category}`}
            />
          </Box>
        )}
      />
    </>
  );
};
