import Constants from 'expo-constants';
const schemaVersion = Constants?.expoConfig?.extra?.schemaVersion;
const matchingWebUrl = Constants?.expoConfig?.extra?.matchingWebUrl;
import { Box, Button, Center } from 'native-base';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Navigation } from './navigation';
import { DriverWarningModal } from './modals/driver-warning-modal';
import { RootState } from '../state/store';
import { fireAuth, fireStore } from '../backend';
import { onAuthStateChanged, Unsubscribe } from 'firebase/auth';
import {
  loadDebugMode,
  setCurrentUser,
  setCurrentUserData,
} from '../state/app/app-configuration-slice';
import { doc, onSnapshot } from 'firebase/firestore';
import { FindItUser } from '../models/game.types';
import { anonymousSignIn } from '../firebase-utilities';
import { Loader } from './loader';
import { setInviteOthersModalVisibility } from '../state/game/game-slice';
import InviteOthersModal from './modals/invite-others-modal';
import { Provider, useDispatch, useSelector } from 'react-redux';
import * as MailComposer from 'expo-mail-composer';
import * as Clipboard from 'expo-clipboard';
import * as SMS from 'expo-sms';
import Toast from 'react-native-root-toast';
import EndGameModal from './modals/end-game-modal';
import ImagePreviewModal from './modals/image-preview-modal';
import { ComposeNewCategoryListModal } from './modals/compose-new-categories-list-modal';
import Drawer from 'react-native-drawer';
import { NavDrawer } from './nav-drawer';
import ResetPasswordModal from './modals/reset-password-modal';
import { DrawerContext } from '../App';

export const AppContent: FC = () => {
  const dispatch = useDispatch();
  const currentUserInStore = useSelector(
    (state: RootState) => state?.appConfiguration?.currentUser
  );
  const currentUserDataInStore = useSelector(
    (state: RootState) => state?.appConfiguration?.currentUserData
  );
  const debugMode = useSelector(
    (state: RootState) => state?.appConfiguration?.debugMode ?? false
  );
  const [userLoggedIn, setUserLoggedIn] = useState<boolean>(
    currentUserInStore != null
  );
  const currentGameId = useSelector(
    (state: RootState) =>
      state?.appConfiguration?.currentUserData?.currentGameId
  );
  useEffect(() => {
    setUserLoggedIn(currentUserInStore != null);
    authStateChangedRef.current = onAuthStateChanged(fireAuth, async (user) => {
      if (
        user?.uid != null &&
        (currentUserDataInStore == null ||
          currentUserDataInStore?.userId != user?.uid ||
          userDataOnSnapshotUnsubscribeRef.current == null)
      ) {
        userDataOnSnapshotUnsubscribeRef.current?.();
        const userRef = doc(fireStore, `users${schemaVersion}`, user.uid);
        userDataOnSnapshotUnsubscribeRef.current = onSnapshot(
          userRef,
          (snapshot) =>
            dispatch(
              setCurrentUserData({ userData: snapshot.data() as FindItUser })
            )
        );
      } else if (fireAuth?.currentUser == null) {
        await anonymousSignIn(debugMode);
      }
      if (currentUserInStore == null && fireAuth.currentUser != null) {
        dispatch(setCurrentUser({ user }));
      }
      dispatch(loadDebugMode({}));
    });
  }, [currentUserInStore]);
  const [mailActionLoading, setMailActionLoading] = useState<boolean>(false);
  const [clipBoardActionLoading, setClipBoardActionLoading] =
    useState<boolean>(false);
  const [textActionLoading, setTextActionLoading] = useState<boolean>(false);

  const authStateChangedRef = useRef<Unsubscribe | null>(null);
  const userDataOnSnapshotUnsubscribeRef = useRef<Unsubscribe | null>(null);

  useEffect(() => {
    return () => {
      authStateChangedRef.current?.();
      userDataOnSnapshotUnsubscribeRef.current?.();
    };
  }, []);

  useEffect(() => {
    const universalUrl = `${matchingWebUrl}/join?gameId=${currentGameId}`;
    if (mailActionLoading) {
      MailComposer.composeAsync({
        body: `Come play Find It! With me, here's the game link: <a href="${universalUrl}">${universalUrl}</a>`,
      })
        .then(() => setMailActionLoading(false))
        .catch(() => setMailActionLoading(false));
    }
    if (clipBoardActionLoading) {
      Clipboard.setStringAsync(universalUrl)
        .then(() => {
          setClipBoardActionLoading(false);
          const toast = Toast.show(`Copied game url to clipboard`, {
            duration: 5000,
          });
          setTimeout(() => {
            Toast.hide(toast);
          }, 5000);
        })
        .catch((err) => {
          setClipBoardActionLoading(false);
        });
    }

    if (textActionLoading) {
      SMS.sendSMSAsync(
        [],
        `Come play Find It! With me, here's the game link: ${universalUrl}`
      )
        .then(() => {
          setTextActionLoading(false);
        })
        .catch(() => setClipBoardActionLoading(false));
    }
  }, [mailActionLoading, clipBoardActionLoading, textActionLoading]);

  const getInviteOthersModalResponse = (
    response: 'message' | 'email' | 'copy' | 'cancel'
  ) => {
    switch (response) {
      case 'message':
        setTextActionLoading(true);
        break;
      case 'email':
        setMailActionLoading(true);
        break;
      case 'copy':
        setClipBoardActionLoading(true);
        break;
      case 'cancel':
      default:
        break;
    }
    dispatch(setInviteOthersModalVisibility({ visible: false }));
  };
  const drawerRef = useRef<Drawer>();
  return (
    <Drawer
      ref={(drawer) => (drawerRef.current = drawer)}
      content={<NavDrawer drawerRef={drawerRef} />}
    >
      {userLoggedIn ? (
        <DrawerContext.Provider value={drawerRef.current}>
          <DriverWarningModal />
          <InviteOthersModal getResponse={getInviteOthersModalResponse} />
          <EndGameModal />
          <ImagePreviewModal />
          <ComposeNewCategoryListModal />
          <ResetPasswordModal />
          <Navigation />
          {mailActionLoading || clipBoardActionLoading || textActionLoading ? (
            <Center height="50%">
              <Loader />
            </Center>
          ) : null}
        </DrawerContext.Provider>
      ) : (
        <Center height="100%">
          <Loader />
        </Center>
      )}
    </Drawer>
  );
};
