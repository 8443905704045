import { MaterialIcons } from '@expo/vector-icons';
import {
  Modal,
  Button,
  Icon,
  Heading,
  VStack,
  Text,
  Center,
} from 'native-base';
import { FC, useEffect, useRef, useState } from 'react';
import { RootState } from '../../state/store';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  setImagePreviewModalCheckbox,
  setImagePreviewModalVisibility,
  updateImageUrl,
} from '../../state/game/game-slice';
import DisplayImageScrollView from '../display-image-scroll-view';
import { getImageFromFireStorage } from '../../firebase-utilities';
import { setComposeNewCategoryModalVisibility } from '../../state/app/app-configuration-slice';

const ImagePreviewModal: FC = () => {
  const dispatch = useDispatch();
  const modalVisible = useSelector(
    (state: RootState) => state?.game?.imagePreviewModalVisible ?? false
  );
  const itemCurrentlyDisplayed = useSelector(
    (state: RootState) => state.game.imagePreviewCheckbox
  );
  const imageUrls = useSelector(
    (state: RootState) => state?.game?.imageUrls ?? {}
  );
  const composeNewCategoryModalVisible = useSelector(
    (state: RootState) =>
      state?.appConfiguration?.composeNewCategoryModalVisible ?? false
  );
  const composeNewCategoryModalVisibleInitiallyRef = useRef(
    composeNewCategoryModalVisible
  );
  useEffect(() => {
    if (modalVisible) {
      composeNewCategoryModalVisibleInitiallyRef.current =
        composeNewCategoryModalVisible;
      dispatch(setComposeNewCategoryModalVisibility({ visible: false }));
    }
  }, [modalVisible]);

  const [itemCurrentlyDisplayedUri, setItemCurrentlyDisplayedUri] = useState<
    string | null
  >(null);

  useEffect(() => {
    if (itemCurrentlyDisplayed != null) {
      const imageUrlInStore = imageUrls[itemCurrentlyDisplayed.id];
      if (!imageUrlInStore) {
        getImageFromFireStorage(
          itemCurrentlyDisplayed.imageUrl.replace('.svg', '.png')
        ).then((imageUrl) => {
          setItemCurrentlyDisplayedUri(imageUrl);
          if (!imageUrl.includes('data:image/svg+xml')) {
            dispatch(
              updateImageUrl({
                checkboxId: itemCurrentlyDisplayed.id,
                imageUrl,
              })
            );
          }
        });
      } else {
        setItemCurrentlyDisplayedUri(imageUrlInStore);
      }
    }
  }, [itemCurrentlyDisplayed]);
  return (
    <Modal
      isOpen={modalVisible}
      onClose={() => {
        dispatch(setImagePreviewModalVisibility({ visible: false }));
        if (composeNewCategoryModalVisibleInitiallyRef.current) {
          dispatch(setComposeNewCategoryModalVisibility({ visible: true }));
        }
      }}
      size="full"
      maxWidth={380}
      marginLeft="auto"
      marginRight="auto"
      alignContent="center"
    >
      <Modal.Content height={330}>
        <Modal.Body>
          <VStack space={1} alignItems="center" height="100%" width="100%">
            <DisplayImageScrollView
              itemCurrentlyDisplayed={itemCurrentlyDisplayed}
              itemCurrentlyDisplayedUri={itemCurrentlyDisplayedUri}
            />
            <Button.Group
              direction="column"
              width="full"
              size="full"
              maxWidth={500}
              _text={{ fontSize: 15 }}
              alignSelf={'flex-end'}
            >
              <Button
                bg="gray.700"
                onPress={() => {
                  dispatch(setImagePreviewModalVisibility({ visible: false }));
                  if (composeNewCategoryModalVisibleInitiallyRef.current) {
                    dispatch(
                      setComposeNewCategoryModalVisibility({ visible: true })
                    );
                  }
                }}
                height={41}
              >
                Close Image Preview
              </Button>
            </Button.Group>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
export default ImagePreviewModal;
