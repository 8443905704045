import Constants from 'expo-constants';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  Button,
  Center,
  Divider,
  FormControl,
  Heading,
  Icon,
  Input,
  Pressable,
  VStack,
  WarningOutlineIcon,
} from 'native-base';
import React, {
  FC,
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { RootStackParamList } from '../components/navigation';
import { MaterialIcons } from '@expo/vector-icons';
import { useDispatch } from 'react-redux';
import { setResetPasswordModalVisibility } from '../state/app/app-configuration-slice';
import {
  linkFirebaseToGoogleNativeSignIn,
  presentToast,
  signInWithGoogle,
  signUserInWithEmailAndPassword,
} from '../firebase-utilities';
import { useSelector } from 'react-redux';
import { fireAuth } from '../backend';
import { RootState } from '../state/store';
import { Platform } from 'react-native';

import { useAuthRequest } from 'expo-auth-session/providers/google';
import { maybeCompleteAuthSession } from 'expo-web-browser';
import { GoogleAuthRequestConfig, ResponseType } from 'expo-auth-session';

maybeCompleteAuthSession();

const googleLoginEnabled =
  Constants?.expoConfig?.extra?.googleLoginEnabled ?? false;
const faceBookLoginEnabled =
  Constants?.expoConfig?.extra?.faceBookLoginEnabled ?? false;
const appleLoginEnabled =
  Constants?.expoConfig?.extra?.appleLoginEnabled ?? false;

const googleAndroidClientId =
  Constants?.expoConfig?.extra?.googleAndroidClientId;
const googleIosClientId = Constants?.expoConfig?.extra?.googleIosClientId;
const googleExpoClientId = Constants?.expoConfig?.extra?.googleExpoClientId;

type LoginRegisterPageProps = NativeStackScreenProps<
  RootStackParamList,
  'loginregister'
>;
export const LoginRegisterPage: FC<LoginRegisterPageProps> = ({
  route,
  navigation,
}) => {
  const debugMode = useSelector(
    (state: RootState) => state.appConfiguration?.debugMode ?? false
  );

  const userLoggedInNonAnonymously = useSelector(
    (state: RootState) =>
      !(state?.appConfiguration?.currentUser?.isAnonymous ?? true)
  );
  const [currentlyDisplayedView, setCurrentlyDisplayedView] = useState<
    'Login' | 'Register'
  >('Login');
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    if (userLoggedInNonAnonymously) {
      navigation.navigate('home');
    }
  }, [userLoggedInNonAnonymously]);

  const loginFormRef = useRef<LoginFormRefType | null>(null);
  useEffect(() => {
    if (loginFormRef.current !== null && currentlyDisplayedView === 'Login') {
      loginFormRef.current?.unsubscribeAll();
      loginFormRef.current?.subscribeToChanges(() => {
        const formIsValid = !(
          loginFormRef.current?.emailIsInvalid ||
          loginFormRef.current?.passwordIsInvalid
        );
        if (formIsValid !== formValid) {
          setFormValid(formIsValid);
        }
      });
    }
  }, [loginFormRef.current, currentlyDisplayedView]);

  const registerFormRef = useRef<RegisterFormRefType | null>(null);
  useEffect(() => {
    if (
      registerFormRef.current !== null &&
      currentlyDisplayedView === 'Register'
    ) {
      registerFormRef.current?.unsubscribeAll();
      registerFormRef.current?.subscribeToChanges(() => {
        const formIsValid = !(
          registerFormRef.current?.emailIsInvalid ||
          registerFormRef.current?.passwordIsInvalid ||
          registerFormRef.current?.firstNameInvalid ||
          registerFormRef.current?.lastNameInvalid
        );
        if (formIsValid !== formValid) {
          setFormValid(formIsValid);
        }
      });
    }
  }, [registerFormRef.current, currentlyDisplayedView]);

  useEffect(() => setFormValid(false), [currentlyDisplayedView]);

  const signInWithUserNameAndPassword = async () => {
    if (!fireAuth.currentUser.isAnonymous) {
      navigation.navigate('home');
      return;
    }
    if (currentlyDisplayedView === 'Login') {
      try {
        await signUserInWithEmailAndPassword(
          loginFormRef.current?.email,
          loginFormRef.current?.password,
          null,
          false,
          debugMode
        );
        presentToast('Login successful!', debugMode, null, 5000);
        navigation.navigate('home');
      } catch (e) {
        if (e && e.code && e.code === 'auth/user-not-found') {
          presentToast(
            `This account doesn't exist. Please Create a new Account or sign in another way`,
            debugMode,
            '#be123c',
            5000
          );
        } else if (e && e.code && e.code === 'auth/wrong-password') {
          presentToast(
            `Wrong username or password, please try again`,
            debugMode,
            '#be123c',
            5000
          );
        } else {
          presentToast(
            `Something went wrong signing in with email/password, please try again later`,
            debugMode,
            '#be123c',
            5000
          );
        }
      }
    } else {
      try {
        await signUserInWithEmailAndPassword(
          registerFormRef.current?.email,
          registerFormRef.current?.password,
          `${registerFormRef.current?.firstName} ${registerFormRef.current?.lastName}`,
          true,
          debugMode
        );
        presentToast('Registration successful!', debugMode, null, 5000);
        navigation.navigate('home');
      } catch (e) {
        if (e && e.code && e.code === 'auth/wrong-password') {
          presentToast(
            `Account already exists for this email, please login`,
            debugMode,
            '#be123c',
            5000
          );
        }
      }
    }
  };

  const signInUserWithGoogle = async () => {
    try {
      if (Platform.OS === 'web') {
        await signInWithGoogle(debugMode);
        presentToast('Login successful!', debugMode, null, 5000);
        navigation.navigate('home');
        return;
      }
      await promptAsync({ useProxy: true });
    } catch (e) {
      if (!e.type || (e.type && e.type !== 'cancel' && e.type !== 'dismiss')) {
        presentToast(
          `Something went wrong signing in with google ${Platform.OS}, please try again later`,
          debugMode,
          '#be123c',
          5000
        );
      }
    }
  };

  const config: Partial<GoogleAuthRequestConfig> = {
    androidClientId: googleAndroidClientId,
    iosClientId: googleIosClientId,
    expoClientId: googleExpoClientId,
    webClientId: googleExpoClientId,
    scopes: ['profile', 'email'],
    responseType: ResponseType.Code,
    shouldAutoExchangeCode: true,
  };
  const [request, response, promptAsync] = useAuthRequest(config);

  useEffect(() => {
    if (response?.type === 'success' && response?.authentication) {
      const { accessToken, idToken } = response.authentication;
      linkFirebaseToGoogleNativeSignIn(debugMode, idToken, accessToken)
        .then(() => {
          presentToast('Login successful!', debugMode, null, 5000);
          navigation.navigate('home');
        })
        .catch(() => {
          presentToast(
            `Something went wrong signing in with google, please try again later`,
            debugMode,
            '#be123c',
            5000
          );
        });
    }
  }, [request, response]);

  return (
    <VStack alignItems="center" paddingLeft={5} paddingRight={5}>
      <Heading
        size="xl"
        textAlign="center"
        _light={{ color: 'secondary.500' }}
        _dark={{ color: 'primary.900' }}
        paddingTop={5}
      >
        Login / Register
      </Heading>
      <Divider
        _light={{ bg: 'secondary.500' }}
        _dark={{ bg: 'primary.900' }}
        marginBottom={5}
        marginTop={5}
      />
      {currentlyDisplayedView === 'Login' ? (
        <LoginForm
          ref={loginFormRef}
          setCurrentlyDisplayedView={setCurrentlyDisplayedView}
        />
      ) : (
        <RegisterForm
          ref={registerFormRef}
          setCurrentlyDisplayedView={setCurrentlyDisplayedView}
        />
      )}
      <Divider marginBottom={5} marginTop={5} />
      <Button.Group
        direction="column"
        width="full"
        maxWidth={500}
        _text={{ fontSize: 15 }}
      >
        <Button
          _dark={{ bg: 'primary.900' }}
          _light={{ bg: 'secondary.500' }}
          isDisabled={!formValid}
          onPress={() => signInWithUserNameAndPassword()}
        >
          {currentlyDisplayedView}
        </Button>
        {googleLoginEnabled &&
        googleIosClientId &&
        googleAndroidClientId &&
        googleExpoClientId ? (
          <Button
            _light={{ bg: 'primary.900' }}
            _dark={{
              bg: 'secondary.500',
            }}
            onPress={() => signInUserWithGoogle()}
          >
            {`${currentlyDisplayedView} with Google`}
          </Button>
        ) : null}
        {faceBookLoginEnabled ? (
          <Button
            _light={{ bg: 'primary.900' }}
            _dark={{ bg: 'secondary.500' }}
            onPress={() => {}}
          >
            {`${currentlyDisplayedView} with Facebook`}
          </Button>
        ) : null}
        {appleLoginEnabled ? (
          <Button
            _light={{ bg: 'primary.900' }}
            _dark={{ bg: 'secondary.500' }}
            onPress={() => {}}
          >
            {`${currentlyDisplayedView} with Apple`}
          </Button>
        ) : null}
      </Button.Group>
    </VStack>
  );
};

type LoginFormRefType = {
  email: string;
  password: string;
  emailIsInvalid: boolean;
  passwordIsInvalid: boolean;
  emailIsDirty: boolean;
  passwordIsDirty: boolean;
  subscribeToChanges: (callback: () => void) => void;
  unsubscribeAll: () => void;
};

type LoginFormRefProps = {
  setCurrentlyDisplayedView: React.Dispatch<
    React.SetStateAction<'Login' | 'Register'>
  >;
};

const LoginFormForwardRefRenderFunction: ForwardRefRenderFunction<
  LoginFormRefType,
  LoginFormRefProps
> = ({ setCurrentlyDisplayedView }, ref) => {
  const dispatch = useDispatch();
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailIsDirty, setEmailIsDirty] = useState(false);
  const [emailIsInvalid, setEmailIsInvalid] = useState(true);
  const emailIsValid = (email: string) => {
    const textIsEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(email);
    const emailIsValid = textIsEmail;
    return emailIsValid;
  };
  const handleEmailChange = (text: string) => {
    if (!emailIsDirty) {
      setEmailIsDirty(true);
    }
    setEmail(text);
    setEmailIsInvalid(!emailIsValid(text));
  };

  const [passwordIsDirty, setPasswordIsDirty] = useState(false);
  const [passwordIsInvalid, setPasswordIsInvalid] = useState(true);
  const passwordIsValid = (password: string) => {
    const passwordLengthGreaterThanOrEqualToSix = password.length >= 6;
    const passwordIsValid = passwordLengthGreaterThanOrEqualToSix;
    return passwordIsValid;
  };
  const parentCallbacks = useRef<Array<() => void>>([]);
  const handlePasswordChange = (text: string) => {
    if (!passwordIsDirty) {
      setPasswordIsDirty(true);
    }
    setPassword(text);
    setPasswordIsInvalid(!passwordIsValid(text));
  };

  const resetForm = () => {
    setEmail('');
    setEmailIsDirty(false);
    setEmailIsInvalid(true);
    setPassword('');
    setPasswordIsDirty(false);
    setPasswordIsInvalid(true);
  };

  useImperativeHandle(
    ref,
    () => ({
      email: email,
      password: password,
      emailIsInvalid: emailIsInvalid,
      passwordIsInvalid: passwordIsInvalid,
      emailIsDirty: emailIsDirty,
      passwordIsDirty: passwordIsDirty,
      subscribeToChanges: (callback: () => void) =>
        parentCallbacks?.current.push(callback),
      unsubscribeAll: () => {
        parentCallbacks.current = parentCallbacks.current = [];
      },
    }),
    [
      email,
      password,
      emailIsInvalid,
      passwordIsInvalid,
      emailIsDirty,
      passwordIsDirty,
    ]
  );

  useEffect(
    () =>
      parentCallbacks?.current.forEach((parentCallback) => parentCallback()),
    [
      email,
      password,
      emailIsInvalid,
      passwordIsInvalid,
      emailIsDirty,
      passwordIsDirty,
    ]
  );

  return (
    <VStack width="100%">
      <Heading size="md" marginBottom={5}>
        Login
      </Heading>
      <FormControl
        isInvalid={emailIsDirty && emailIsInvalid}
        w="100%"
        marginBottom={5}
        isRequired={emailIsDirty && true}
      >
        <Input
          _dark={{ focusOutlineColor: 'secondary.500' }}
          _light={{ focusOutlineColor: 'primary.900' }}
          variant="outline"
          placeholder="Email..."
          isInvalid={emailIsDirty && emailIsInvalid}
          type="text"
          onChangeText={handleEmailChange}
          value={email}
          InputRightElement={
            <Pressable onPress={() => setEmail('')}>
              <Icon
                as={<MaterialIcons name="cancel" />}
                size={5}
                mr="2"
                color="muted.400"
              />
            </Pressable>
          }
        />
        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
          You must provide a valid email...
        </FormControl.ErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={passwordIsDirty && passwordIsInvalid}
        w="100%"
        isRequired={passwordIsDirty && true}
      >
        <Input
          _dark={{ focusOutlineColor: 'secondary.500' }}
          _light={{ focusOutlineColor: 'primary.900' }}
          variant="outline"
          placeholder="Password..."
          isInvalid={passwordIsDirty && passwordIsInvalid}
          type={showPasswordField ? 'text' : 'password'}
          onChangeText={handlePasswordChange}
          value={password}
          InputRightElement={
            <>
              <Pressable onPress={() => setPassword('')}>
                <Icon
                  as={<MaterialIcons name="cancel" />}
                  size={5}
                  mr="2"
                  color="muted.400"
                />
              </Pressable>
              <Pressable onPress={() => setShowPasswordField((prev) => !prev)}>
                <Icon
                  as={
                    <MaterialIcons
                      name={showPasswordField ? 'visibility' : 'visibility-off'}
                    />
                  }
                  size={5}
                  mr="2"
                  color="muted.400"
                />
              </Pressable>
            </>
          }
        />
        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
          Password must be at least 6 characters...
        </FormControl.ErrorMessage>
      </FormControl>
      <Heading
        _light={{ color: 'secondary.500' }}
        _dark={{ color: 'primary.900' }}
        marginBottom={10}
        marginTop={2}
        size="sm"
        onPress={() =>
          dispatch(setResetPasswordModalVisibility({ visible: true }))
        }
      >
        Forgot password?
      </Heading>
      <Center>
        <Heading
          size="md"
          _light={{ color: 'secondary.500' }}
          _dark={{ color: 'primary.900' }}
          onPress={() => {
            resetForm();
            setCurrentlyDisplayedView('Register');
          }}
        >
          Create a new Account
        </Heading>
      </Center>
    </VStack>
  );
};
const LoginForm = forwardRef(LoginFormForwardRefRenderFunction);

type RegisterFormRefType = {
  email: string;
  emailIsInvalid: boolean;
  emailIsDirty: boolean;
  password: string;
  passwordIsDirty: boolean;
  passwordIsInvalid: boolean;
  firstName: string;
  firstNameInvalid: boolean;
  firstNameIsDirty: boolean;
  lastName: string;
  lastNameInvalid: boolean;
  lastNameIsDirty: boolean;
  subscribeToChanges: (callback: () => void) => void;
  unsubscribeAll: () => void;
};

type RegisterFormRefProps = {
  setCurrentlyDisplayedView: React.Dispatch<
    React.SetStateAction<'Login' | 'Register'>
  >;
};

const RegisterFormForwardRefRenderFunction: ForwardRefRenderFunction<
  RegisterFormRefType,
  RegisterFormRefProps
> = ({ setCurrentlyDisplayedView }, ref) => {
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [email, setEmail] = useState('');
  const [emailIsDirty, setEmailIsDirty] = useState(false);
  const [emailIsInvalid, setEmailIsInvalid] = useState(true);
  const emailIsValid = (email: string) => {
    const textIsEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(email);
    const emailIsValid = textIsEmail;
    return emailIsValid;
  };
  const handleEmailChange = (text: string) => {
    if (!emailIsDirty) {
      setEmailIsDirty(true);
    }
    setEmail(text);
    setEmailIsInvalid(!emailIsValid(text));
  };

  const [password, setPassword] = useState('');
  const [passwordIsDirty, setPasswordIsDirty] = useState(false);
  const [passwordIsInvalid, setPasswordIsInvalid] = useState(true);
  const passwordIsValid = (password: string) => {
    const passwordLengthGreaterThanOrEqualToSix = password.length >= 6;
    const passwordIsValid = passwordLengthGreaterThanOrEqualToSix;
    return passwordIsValid;
  };
  const handlePasswordChange = (text: string) => {
    if (!passwordIsDirty) {
      setPasswordIsDirty(true);
    }
    setPassword(text);
    setPasswordIsInvalid(!passwordIsValid(text));
  };

  const [firstName, setFirstName] = useState('');
  const [firstNameIsDirty, setFirstNameIsDirty] = useState(false);
  const [firstNameIsInvalid, setFirstNameIsInvalid] = useState(true);
  const firstNameIsValid = (firstName: string) => {
    const firstNameLengthGreaterThanZero = firstName.length > 0;
    const firstNameIsValid = firstNameLengthGreaterThanZero;
    return firstNameIsValid;
  };
  const handleFirstNameChange = (text: string) => {
    if (!firstNameIsDirty) {
      setFirstNameIsDirty(true);
    }
    setFirstName(text);
    setFirstNameIsInvalid(!firstNameIsValid(text));
  };

  const [lastName, setLastName] = useState('');
  const [lastNameIsDirty, setLastNameIsDirty] = useState(false);
  const [lastNameIsInvalid, setLastNameIsInvalid] = useState(true);
  const lastNameIsValid = (lastName: string) => {
    const lastNameLengthGreaterThanZero = lastName.length > 0;
    const lastNameIsValid = lastNameLengthGreaterThanZero;
    return lastNameIsValid;
  };
  const handleLastNameChange = (text: string) => {
    if (!lastNameIsDirty) {
      setLastNameIsDirty(true);
    }
    setLastName(text);
    setLastNameIsInvalid(!lastNameIsValid(text));
  };

  const resetForm = () => {
    setEmail('');
    setEmailIsDirty(false);
    setEmailIsInvalid(true);
    setPassword('');
    setPasswordIsDirty(false);
    setPasswordIsInvalid(true);
    setFirstName('');
    setFirstNameIsDirty(false);
    setFirstNameIsInvalid(true);
    setLastName('');
    setLastNameIsDirty(false);
    setLastNameIsInvalid(true);
  };

  const parentCallbacks = useRef<Array<() => void>>([]);

  useImperativeHandle(
    ref,
    () => ({
      email: email,
      emailIsInvalid: emailIsInvalid,
      emailIsDirty: emailIsDirty,
      password: password,
      passwordIsDirty: passwordIsDirty,
      passwordIsInvalid: passwordIsInvalid,
      firstName: firstName,
      firstNameInvalid: firstNameIsInvalid,
      firstNameIsDirty: firstNameIsDirty,
      lastName: lastName,
      lastNameInvalid: lastNameIsInvalid,
      lastNameIsDirty: lastNameIsDirty,
      subscribeToChanges: (callback: () => void) =>
        parentCallbacks?.current.push(callback),
      unsubscribeAll: () =>
        (parentCallbacks.current = parentCallbacks.current = []),
    }),
    [
      email,
      emailIsInvalid,
      emailIsDirty,
      password,
      passwordIsInvalid,
      passwordIsDirty,
      firstName,
      firstNameIsInvalid,
      firstNameIsDirty,
      lastName,
      lastNameIsInvalid,
      lastNameIsDirty,
    ]
  );

  useEffect(
    () =>
      parentCallbacks?.current.forEach((parentCallback) => parentCallback()),
    [
      email,
      emailIsInvalid,
      emailIsDirty,
      password,
      passwordIsInvalid,
      passwordIsDirty,
      firstName,
      firstNameIsInvalid,
      firstNameIsDirty,
      lastName,
      lastNameIsInvalid,
      lastNameIsDirty,
    ]
  );

  return (
    <VStack width="100%">
      <Heading size="md" marginBottom={5}>
        Register
      </Heading>
      <FormControl
        isInvalid={firstNameIsDirty && firstNameIsInvalid}
        w="100%"
        marginBottom={5}
        isRequired={firstNameIsDirty && true}
      >
        <Input
          _dark={{ focusOutlineColor: 'secondary.500' }}
          _light={{ focusOutlineColor: 'primary.900' }}
          variant="outline"
          placeholder="First Name..."
          isInvalid={firstNameIsDirty && firstNameIsInvalid}
          type="text"
          onChangeText={handleFirstNameChange}
          value={firstName}
          InputRightElement={
            <Pressable onPress={() => setFirstName('')}>
              <Icon
                as={<MaterialIcons name="cancel" />}
                size={5}
                mr="2"
                color="muted.400"
              />
            </Pressable>
          }
        />
        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
          You must provide a first name
        </FormControl.ErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={lastNameIsDirty && lastNameIsInvalid}
        w="100%"
        marginBottom={5}
        isRequired={lastNameIsDirty && true}
      >
        <Input
          _dark={{ focusOutlineColor: 'secondary.500' }}
          _light={{ focusOutlineColor: 'primary.900' }}
          variant="outline"
          placeholder="Last Name..."
          isInvalid={lastNameIsDirty && lastNameIsInvalid}
          type="text"
          onChangeText={handleLastNameChange}
          value={lastName}
          InputRightElement={
            <Pressable onPress={() => setLastName('')}>
              <Icon
                as={<MaterialIcons name="cancel" />}
                size={5}
                mr="2"
                color="muted.400"
              />
            </Pressable>
          }
        />
        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
          You must provide a last name
        </FormControl.ErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={emailIsDirty && emailIsInvalid}
        w="100%"
        marginBottom={5}
        isRequired={emailIsDirty && true}
      >
        <Input
          _dark={{ focusOutlineColor: 'secondary.500' }}
          _light={{ focusOutlineColor: 'primary.900' }}
          variant="outline"
          placeholder="Email..."
          isInvalid={emailIsDirty && emailIsInvalid}
          type="text"
          onChangeText={handleEmailChange}
          value={email}
          InputRightElement={
            <Pressable onPress={() => setEmail('')}>
              <Icon
                as={<MaterialIcons name="cancel" />}
                size={5}
                mr="2"
                color="muted.400"
              />
            </Pressable>
          }
        />
        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
          You must provide a valid email...
        </FormControl.ErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={passwordIsDirty && passwordIsInvalid}
        w="100%"
        isRequired={passwordIsDirty && true}
        marginBottom={10}
      >
        <Input
          _dark={{ focusOutlineColor: 'secondary.500' }}
          _light={{ focusOutlineColor: 'primary.900' }}
          variant="outline"
          placeholder="Password..."
          isInvalid={passwordIsDirty && passwordIsInvalid}
          type={showPasswordField ? 'text' : 'password'}
          onChangeText={handlePasswordChange}
          value={password}
          InputRightElement={
            <>
              <Pressable onPress={() => setPassword('')}>
                <Icon
                  as={<MaterialIcons name="cancel" />}
                  size={5}
                  mr="2"
                  color="muted.400"
                />
              </Pressable>
              <Pressable onPress={() => setShowPasswordField((prev) => !prev)}>
                <Icon
                  as={
                    <MaterialIcons
                      name={showPasswordField ? 'visibility' : 'visibility-off'}
                    />
                  }
                  size={5}
                  mr="2"
                  color="muted.400"
                />
              </Pressable>
            </>
          }
        />
        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
          Password must be at least 6 characters...
        </FormControl.ErrorMessage>
      </FormControl>
      <Center>
        <Heading
          size="md"
          _light={{ color: 'secondary.500' }}
          _dark={{ color: 'primary.900' }}
          onPress={() => {
            resetForm();
            setCurrentlyDisplayedView('Login');
          }}
        >
          Already have an account? Login here...
        </Heading>
      </Center>
    </VStack>
  );
};

const RegisterForm = forwardRef(RegisterFormForwardRefRenderFunction);
