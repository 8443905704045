import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  Divider,
  HStack,
  Heading,
  ScrollView,
  Text,
  VStack,
} from 'native-base';
import React, { FC, useEffect, useRef, useState } from 'react';
import { RootStackParamList } from '../components/navigation';
import { useSelector } from 'react-redux';
import { RootState } from '../state/store';
import { GameHistoryForUser, getGameHistoryForUserId } from '../game-utilities';

type HistoryPageProps = NativeStackScreenProps<RootStackParamList, 'history'>;
export const HistoryPage: FC<HistoryPageProps> = ({ route, navigation }) => {
  const currentUser = useSelector(
    (state: RootState) => state.appConfiguration?.currentUser
  );
  return (
    <VStack alignItems="center" paddingLeft={5} paddingRight={5}>
      <Heading
        size="xl"
        textAlign="center"
        _light={{ color: 'secondary.500' }}
        _dark={{ color: 'primary.900' }}
        paddingTop={5}
      >
        Game History
      </Heading>
      <Divider
        _light={{ bg: 'secondary.500' }}
        _dark={{ bg: 'primary.900' }}
        marginBottom={2.5}
        marginTop={5}
      />
      {currentUser?.isAnonymous ?? true ? (
        <Text padding={1}>Login or register to view your game history</Text>
      ) : (
        <HistoryList />
      )}
    </VStack>
  );
};

const HistoryList: React.FC = () => {
  const currentUserId = useSelector(
    (state: RootState) => state.appConfiguration?.currentUser?.uid
  );
  const [gameHistory, setGameHistory] = useState<GameHistoryForUser | null>(
    null
  );
  useEffect(() => {
    if (currentUserId == null) {
      return;
    }
    getGameHistoryForUserId(currentUserId)
      .then((gameHistory) => setGameHistory(gameHistory))
      .catch((err) => {
        console.error(err);
        setGameHistory(null);
      });
  }, [currentUserId]);
  const formatTime = (timeInSeconds: number): string => {
    const totalHours = Math.floor(timeInSeconds / 3600);
    const remainderMinutes = Math.floor((timeInSeconds % 3600) / 60);
    return `${totalHours} ${
      totalHours == 1 ? 'hour' : 'hours'
    }, ${remainderMinutes} minutes`;
  };
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const formatDate = (date: Date): string =>
    `${months[date.getMonth()]} ${date.getDay()}, ${date.getFullYear()}`;
  if (gameHistory == null) {
    return (
      <Text padding={1}>
        No game history to display. Have you played a game yet?
      </Text>
    );
  }
  return (
    <ScrollView maxHeight={600} width="100%">
      <VStack width="100%">
        <Heading size="md">TOTALS</Heading>
        <VStack width="80%">
          <HStack justifyContent="space-between">
            <Text padding={1}>Games Played:</Text>
            <Text padding={1}>{gameHistory.gamesPlayed}</Text>
          </HStack>
          <HStack justifyContent="space-between">
            <Text padding={1}>Game Time</Text>
            <Text padding={1}>
              {formatTime(gameHistory.totalPlayTimeSeconds)}
            </Text>
          </HStack>
          <HStack justifyContent="space-between">
            <Text padding={1}>Items Found</Text>
            <Text padding={1}>
              {gameHistory.totalItemsFound} of {gameHistory.totalItems} of{' '}
              {gameHistory.gameHistories.length}
            </Text>
          </HStack>
        </VStack>
        <Divider marginTop={2.5} marginBottom={2.5} />
        {gameHistory.gameHistories.map((history) => (
          <>
            <Heading size="md">GAME {history.gameNumber}</Heading>
            <VStack width="80%">
              <HStack></HStack>
              <HStack justifyContent="space-between">
                <Text padding={1}>Start Date:</Text>
                <Text padding={1}>{formatDate(history.startDate)}</Text>
              </HStack>
              <HStack justifyContent="space-between">
                <Text padding={1}>Game Time</Text>
                <Text padding={1}>{formatTime(history.gameTimeSeconds)}</Text>
              </HStack>
              <HStack justifyContent="space-between">
                <Text padding={1}>Items Found</Text>
                <Text padding={1}>
                  {history.itemsFound} of {history.totalItems}
                </Text>
              </HStack>
            </VStack>
            <Divider marginTop={2.5} marginBottom={2.5} />
          </>
        ))}
      </VStack>
    </ScrollView>
  );
};
