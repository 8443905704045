import Constants from 'expo-constants';
import { getApp, getApps, initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator, Auth } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import {
  initializeAuth,
  getReactNativePersistence,
} from 'firebase/auth/react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

const firebaseConfig = Constants?.expoConfig?.extra?.firebase;
const useEmulators = Constants?.expoConfig?.extra?.useEmulators ?? false;
const fireApp =
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
const fireAuth =
  getApps().length === 0
    ? initializeAuth(fireApp, {
        persistence: getReactNativePersistence(AsyncStorage),
      })
    : getAuth(fireApp);

const fireStore = getFirestore(fireApp);
const fireFunctions = getFunctions(fireApp);
const fireStorage = getStorage(fireApp);

if (useEmulators === true) {
  connectAuthEmulator(fireAuth, 'http://localhost:9099', {
    disableWarnings: true,
  });
  connectStorageEmulator(fireStorage, 'localhost', 9199);
  connectFirestoreEmulator(fireStore, 'localhost', 8081);
  connectFunctionsEmulator(fireFunctions, 'localhost', 5001);
}

export { fireStore, fireFunctions, fireAuth, fireStorage };
